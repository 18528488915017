import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomInputField from 'fields/InputField';

export const InputField = styled(CustomInputField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const AddressPreview = styled(Typography)(({ theme }) => ({
  textDecoration: 'underline',
  width: 'fit-content',
  cursor: 'pointer',
  marginBottom: theme.spacing(1.5),
}));
