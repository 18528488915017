import { FC, ReactNode } from 'react';

import { CustomDrawer } from 'components/BottomDrawer/index.styled';

import useStoryDimensions from 'hooks/useStoryDimensions';

interface BottomDrawerProps {
  isOpen: boolean;
  closeDrawer: (close: boolean) => void;
  children: ReactNode;
}

const BottomDrawer:FC<BottomDrawerProps> = ({ isOpen, closeDrawer, children }) => {
  const { width } = useStoryDimensions();

  const toggleDrawer = (event: { type: string; key: string }) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    closeDrawer(!isOpen);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <CustomDrawer
      width={width}
      anchor="bottom"
      open={isOpen}
      onClose={toggleDrawer}
    >
      <div className="content hidden-scroll">
        {children}
      </div>
    </CustomDrawer>
  );
};

export default BottomDrawer;
