import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Root } from 'components/TestCC/index.styled';

interface TestCCProps {
  label?: string;
  sx?: SxProps;
}

const TestCC: FC<TestCCProps> = ({
  label = 'label.testCC',
  sx = {},
}) => {
  const intl = useIntl();

  return (
    <Root sx={sx}>
      <Typography variant="body1" color="common.white">
        {intl.formatMessage({ id: label })}
      </Typography>
    </Root>
  );
};

export default TestCC;
