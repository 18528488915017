import { AddressType } from 'constants/enums';

export const DEFAULT_ADDRESS = {
  name: '',
  line1: '',
  line2: '',
  phoneNumber: '',
  countryCode: '',
  state: '',
  city: '',
  postalCode: '',
  type: AddressType.Shipping,
};
