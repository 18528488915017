import { styled } from '@mui/material/styles';

export const Stats = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),

  '& .MuiAvatar-root': {
    backgroundColor: theme.palette.common.black,
  },
}));
