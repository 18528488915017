import { Box, Button, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { AddressWrapper } from 'containers/Checkout/ChooseAddress/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { saveAddressToCart } from 'services/ShoppingCart';

import { Address } from 'models/address.interface';
import { formatAddress } from 'utils/formatters';

interface ChooseAddressProps {
  addresses: Record<string, Address>;
  currentAddress: Address;
  onAddAddress: () => void;
  onClose: () => void;
}

const ChooseAddress: FC<ChooseAddressProps> = ({
  addresses,
  currentAddress,
  onAddAddress,
  onClose,
}) => {
  const intl = useIntl();
  const { fbUser } = useContext(FbUserContext);
  const [selectedAddress, setSelectedAddress] = useState<Address>(currentAddress);

  const handleSaveSelection = () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    saveAddressToCart({ ...selectedAddress, instructions: currentAddress?.instructions || '' }, fbUser);
    onClose();
  };

  return (
    <Box width="100%">
      <Typography variant="h1" mb={3}>
        {intl.formatMessage({ id: 'label.changeDeliveryAddress' })}
      </Typography>
      <Box display="flex" flexDirection="column" gap={1} mb={1}>
        {Object.values(addresses).map((address) => (
          <AddressWrapper
            key={address?.id}
            onClick={() => setSelectedAddress(address)}
            isActive={selectedAddress?.id === address?.id}
          >
            <Typography variant="subtitle2" fontWeight={400}>
              {formatAddress(address)}
            </Typography>
          </AddressWrapper>
        ))}
      </Box>
      <Button fullWidth className="big" variant="outlined" onClick={onAddAddress}>
        {intl.formatMessage({ id: 'button.addAddress' })}
      </Button>
      <Box display="flex" alignItems="center" gap={1} mt={3}>
        <Button fullWidth className="big" variant="outlined" onClick={onClose}>
          {intl.formatMessage({ id: 'button.close' })}
        </Button>
        <Button fullWidth className="big" variant="contained" onClick={handleSaveSelection}>
          {intl.formatMessage({ id: 'button.save' })}
        </Button>
      </Box>
    </Box>
  );
};

export default ChooseAddress;
