import { Voucher } from 'models/voucher.interface';

export const stringToVoucher = (str: string) => {
  if (!str || !str.trim().length) {
    return null;
  }

  const [type, id] = str.split(':', 2);
  return {
    type,
    id,
  };
};

export const voucherToString = ({ type, id }: Partial<Voucher>) => `${type}:${id}`;

export const isValid = (voucher: Voucher)=> (
  voucher?.expiresAt && voucher?.expiresAt > new Date().getTime()
);
