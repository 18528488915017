import { Box, FormControl, FormHelperText, FormLabel, Tooltip, Typography } from '@mui/material';
import { FC, ChangeEvent } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Root } from 'fields/PhoneNumberField/index.styled';

import InfoIcon from 'assets/icons/InfoIcon';

interface PhoneNumberFieldProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
  name: string;
  rawNumber: string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  country?: string;
  helperText?: string;
}

const PhoneNumberField :FC<PhoneNumberFieldProps> = ({
  name,
  rawNumber,
  onChange,
  country = 'us',
  required = false,
  label,
  tooltip,
  fullWidth = false,
  error = false,
  helperText = '',
  ...remainingProps
}) => {
  const handleChanges = (formattedValue: string) => {
    onChange({
      target: {
        name,
        value: formattedValue,
      },
    } as ChangeEvent<HTMLInputElement>);
  };

  return (
    <Root hasError={error}>
      <FormControl component="div" fullWidth={fullWidth}>
        {label && (
          <Box display="flex" alignItems="center" gap={1} mb={0.5}>
            <FormLabel required={required}>
              <Typography component="span" variant="body2" color="textPrimary">
                {label}
              </Typography>
            </FormLabel>
            {tooltip && (
              <Tooltip arrow title={tooltip} placement="top">
                <Box display="flex" alignItems="center">
                  <InfoIcon size={18} />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
        <PhoneInput
          {...remainingProps}
          inputProps={{
            name,
            id: `input-${name}`,
          }}
          specialLabel=""
          value={rawNumber}
          country={country}
          onChange={handleChanges}
          containerClass="root"
        />
        {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </FormControl>
    </Root>
  );
};

export default PhoneNumberField;
