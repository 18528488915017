import MuiContainer from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import { xContentPadding } from 'assets/theme/customSpacing';

export const Root = styled('div')<{ backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  backgroundColor,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',

  '& .appLogo': {
    position: 'absolute',
    left: '30px',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  maxHeight: '100vh',
  overflow: 'hidden',
  color: theme.palette.common.white,
  padding: theme.spacing(1, 0),
}));

export const HeaderWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down(600)]: {
    maxWidth: '455px',
  },
  position: 'relative',
  display: 'flex',
  alignSelf: 'center',
}));

export const Container = styled(MuiContainer)(() => ({
  '&.MuiContainer-root': {
    paddingLeft: `${xContentPadding}px`,
    paddingRight: `${xContentPadding}px`,
    height: '-webkit-fill-available',
  },
}));
