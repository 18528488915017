import { Box, Button } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { InputField } from 'containers/Checkout/Coupon/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { useAppSelector } from 'hooks/useRedux';
import { saveCouponToCart } from 'services/ShoppingCart';
import { cartSelector } from 'store/cart/selectors';

const Coupon = () => {
  const intl = useIntl();
  const { fbUser } = useContext(FbUserContext);
  const { coupon: currentCoupon } = useAppSelector(cartSelector);
  const [coupon, setCouponState] = useState<string>(currentCoupon || '');

  useEffect(() => {
    if (!coupon && currentCoupon !== coupon) {
      setCouponState(currentCoupon || '');
    }
  }, [currentCoupon]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setCouponState(value);
  };

  const handleApply = () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    saveCouponToCart(coupon, fbUser);
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <InputField
        name="coupon"
        value={coupon}
        onChange={handleChange}
        autoComplete="off"
        placeholder={intl.formatMessage({ id: 'placeholder.enterCouponCode' })}
        variant="outlined"
        fullWidth
      />
      <Button variant="outlined" onClick={handleApply}>
        {intl.formatMessage({ id: 'button.apply' })}
      </Button>
    </Box>
  );
};

export default Coupon;
