import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/store';
import { User } from 'firebase/auth';

import { addToMyShoppingCart, updateProductQuantity } from 'services/ShoppingCart';

import { ApiStatus } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { Cart } from 'models/cart.interface';

export interface CartState {
  data: Cart;
  status: ApiStatus;
  totalProductsQuantity: number;
  hasCouponAppliedSuccessfully: boolean;
}

export const initialState: CartState = {
  data: {
    products: null,
    deliveryAddress: null,
    paymentInfo: null,
    coupon: null,
    negotiatedAmount: null,
    redemptionAmount: null,
  },
  status: ApiStatus.idle,
  totalProductsQuantity: 0,
  hasCouponAppliedSuccessfully: false,
};

export const addProductToCart = createAsyncThunk(
  'cart/addProductToCart',
  async ({ bounty, user }: { bounty: Bounty; user: User }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const products = state.cart?.data?.products || {}; // Access current cart state

    const addedProduct = Object.values(products).find(
      ({ bountyId }) => bountyId === bounty?.id
    );

    if (addedProduct) {
      updateProductQuantity({ user, productInfo: addedProduct, newQuantity: addedProduct.quantity + 1 });
    } else {
      addToMyShoppingCart(bounty, user);
    }

    dispatch(setTotalProductsQuantity(state.cart.totalProductsQuantity + 1));
  }
);

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<ApiStatus>) => {
      state.status = action?.payload;
      return state;
    },
    setTotalProductsQuantity: (state, action: PayloadAction<number>) => {
      state.totalProductsQuantity = action?.payload;
      return state;
    },
    setCart: (state, action: PayloadAction<Cart>) => {
      state.data = action?.payload;
      return state;
    },
    setHasCouponAppliedSuccessfully: (state, action: PayloadAction<boolean>) => {
      state.hasCouponAppliedSuccessfully = action?.payload;
      return state;
    },
    resetCart: () => initialState,
  },
});

export const {
  setTotalProductsQuantity,
  setHasCouponAppliedSuccessfully,
  setCart,
  setStatus,
  resetCart,
} = cartSlice.actions;

export default cartSlice.reducer;
