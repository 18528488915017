import { Box, Button, Divider, Typography } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import BottomDrawer from 'components/BottomDrawer';
import Confetti from 'components/Confetti';
import DownloadAppOverlay from 'components/DownloadAppOverlay';
import LoadingStory from 'components/LoadingStory';
import StoryDialog from 'components/StoryDialog';
import BottomNavigation from 'containers/Checkout/BottomNavigation';
import Coupon from 'containers/Checkout/Coupon';
import DeliveryDetails from 'containers/Checkout/DeliveryDetails';
import { Content } from 'containers/Checkout/index.styled';
import InsufficientAmount from 'containers/Checkout/InsufficientAmount';
import PaymentDetails from 'containers/Checkout/PaymentDetails';
import ProductDetails from 'containers/Checkout/ProductDetails';
import RedeemTokens from 'containers/Checkout/RedeemTokens';
import RedemptionProcess from 'containers/Checkout/RedemptionProcess';
import SectionWrapper from 'containers/Checkout/SectionWrapper';
import Summary from 'containers/Checkout/Summary';
import SigninInvitation from 'containers/SigninInvitation';
import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getAccountBalance } from 'services/Account';
import { calculateOrderSummary, placeOrder } from 'services/ShoppingCart';
import { resetCart } from 'store/cart/cartSlice';
import { cartSelector, isLoadingSelector } from 'store/cart/selectors';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';
import { fireConfetti, setShowDownloadOverlay } from 'store/system/systemSlice';

import { COUNTRIES_OPTIONS } from 'constants/countries';
import { InvalidReason, PointCurrency } from 'constants/enums';
import { Reward } from 'models/money.interface';
import { OrderSection } from 'models/order.interface';
import { getShoppingCartPrices } from 'utils/cart';
import { handleApiErrors } from 'utils/error';
import { getPointsAmount, isPointsOnly } from 'utils/point';
import { getProductErrorMessage } from 'utils/product';
import { hasMoney, sum } from 'utils/reward';

import { checkProductsRequireShipping, getMainInvalidReason } from './utils';

const Checkout = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { fbUser } = useContext(FbUserContext);
  const cart = useAppSelector(cartSelector);
  const isLoading = useAppSelector(isLoadingSelector);
  const [inAppSection, setInAppSection] = useState<OrderSection | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [currentPoints, setCurrentPoints] = useState<number>(0);
  const [isLoadingTokens, setIsLoadingTokens] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [insufficientAmount, setInsufficientAmount] = useState<Reward | null>(null);
  const [isPlaceOrderDisabled, setIsPlaceOrderDisabled] = useState<boolean>(false);
  const [hasPlacedOrder, setHasPlacedOrder] = useState<boolean>(false);

  const { products, deliveryAddress, paymentInfo } = cart || {};
  const totalProducts = products ? Object.keys(products).length : 0;
  const isCartEmpty = !products || !totalProducts;
  const deliveryCountry = COUNTRIES_OPTIONS.find(({ value }) => (
    value === deliveryAddress?.countryCode
  ))?.label;

  useEffect(() => {
    if(!fbUser?.isAnonymous) {
      fetchMainPointCurrency();
    }
  }, [fbUser?.isAnonymous]);

  useEffect(() => {
    if (hasPlacedOrder) {
      dispatch(fireConfetti(true));
    }
  }, [hasPlacedOrder]);

  const fetchMainPointCurrency = async () => {
    setIsLoadingTokens(true);

    try {
      const { data } = await getAccountBalance(PointCurrency.Systkn);

      if (data?.available?.points) {
        setCurrentPoints(getPointsAmount(data?.available, PointCurrency.Systkn));
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoadingTokens(false);
    }
  };

  const handlePlaceOrder = async () => {
    if (fbUser?.isAnonymous) {
      dispatch(openModalSignIn());
      return;
    }

    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.cannotSaveResponse' }));
      return;
    }

    if (!products || !Object.keys(products).length) {
      toast.warning(intl.formatMessage({ id: 'label.yourCartIsEmpty' }));
      return;
    }

    const totalPrice = sum(getShoppingCartPrices(cart));
    const isCartFree = !!inAppSection && !hasMoney(inAppSection?.total);
    const mainInvalidReason = getMainInvalidReason(inAppSection?.invalidProducts);

    if (checkProductsRequireShipping(products) && isEmpty(deliveryAddress)) {
      toast.warning(intl.formatMessage({ id: 'warning.pleaseAddDeliveryAddress' }));
      return;
    }

    if (mainInvalidReason) {
      const warningMessage = mainInvalidReason === InvalidReason.InsufficientProductAmount
        ? intl.formatMessage({ id: 'warning.productsOutOfStock' })
        : intl.formatMessage({ id: 'warning.productsCantBeShipped' }, { country: deliveryCountry });
      toast.warning(warningMessage);
      return;
    }

    if (!paymentInfo && totalPrice.money && !isCartFree) {
      toast.warning(intl.formatMessage({ id: 'warning.pleaseAddPaymentMethod' }));
      return;
    }

    if (isPointsOnly(totalPrice)) {
      const requiredPoints = totalPrice?.points ? getPointsAmount(totalPrice?.points, PointCurrency.Systkn) : 0;

      if (currentPoints < requiredPoints) {
        const value = requiredPoints > 1
          ? `${requiredPoints} ${intl.formatMessage({ id: 'label.tokens' })}`
          : `1 ${intl.formatMessage({ id: 'label.token' })}`;
        toast.warning(intl.formatMessage({ id: 'warning.notEnoughPointsToPlace' }, { value }));
        return;
      }
    }

    try {
      setIsSubmitting(true);
      const { data } = await calculateOrderSummary(cart, fbUser);

      if (data?.summary?.insufficientAccountAmount) {
        setIsDialogOpen(true);
        setInsufficientAmount(data?.summary?.insufficientAccountAmount);
      } else {
        await placeOrder(cart, fbUser);
        toast.success(intl.formatMessage({ id: 'label.orderHasBeenPlaced' }));
        dispatch(resetCart());
        setHasPlacedOrder(true);
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenDrawer = () => setIsDrawerOpen(true);

  const handleCloseDrawer = () => setIsDrawerOpen(false);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setInsufficientAmount(null);
  };

  const handleShowDownloadOverlay = () => dispatch(setShowDownloadOverlay(true));

  if (isLoading) {
    return <LoadingStory />;
  }

  if (hasPlacedOrder) {
    return (
      <Box position="relative" height="100%">
        <Content display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h1" color="textPrimary" textAlign="center" maxWidth="475px">
            {intl.formatMessage({ id: 'label.yourOrderOnItsWay' })}🚀
          </Typography>
          <Typography variant="h2" color="textPrimary" fontWeight={400} textAlign="center" maxWidth="475px">
            {intl.formatMessage({ id: 'label.trackItEasilyInMobileApp' })}
          </Typography>
          <Button onClick={handleShowDownloadOverlay} variant="contained">
            {intl.formatMessage({ id: 'button.downloadApp' })}
          </Button>
        </Content>
        <Confetti />
        <DownloadAppOverlay />
        <BottomNavigation onPlaceOrder={handlePlaceOrder} />
      </Box>
    );
  }

  if (isCartEmpty) {
    return (
      <>
        <Content>
          <Typography variant="h1" textAlign="center" color="textPrimary">
            {intl.formatMessage({ id: 'label.cart' })}
          </Typography>
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h2" color="textPrimary" textAlign="center" maxWidth="475px">
              {intl.formatMessage({ id: 'label.yourCartIsEmpty' })}
            </Typography>
          </Box>
        </Content>
        <BottomNavigation onPlaceOrder={handlePlaceOrder} isLoggedIn={!fbUser?.isAnonymous} />
      </>
    );
  }

  return (
    <Box position="relative" height="100%">
      <Content className="hidden-scroll">
        <Typography variant="h1" textAlign="center" color="textPrimary">
          {intl.formatMessage({ id: 'label.cart' })}
        </Typography>
        <SectionWrapper title={intl.formatMessage({ id: 'label.items' })}>
          {products && Object.values(products).map((product, index) => {
            const foundProduct = inAppSection?.invalidProducts?.find(({ bountyId }) => bountyId === product.bountyId);
            const errorMessage = foundProduct
              ? getProductErrorMessage(foundProduct, product?.merchant?.name, deliveryCountry)
              : null;

            return (
              <>
                <ProductDetails key={product?.id} product={product} errorMessage={errorMessage} />
                {index + 1 < totalProducts && <Divider sx={{ my: 2 }} />}
              </>
            );
          })}
        </SectionWrapper>
        {!fbUser?.isAnonymous && (
          <SectionWrapper
            title={intl.formatMessage({ id: 'label.redeemTokens' })}
            hasInfoIcon
            onSeeInfo={handleOpenDrawer}
          >
            <RedeemTokens
              tokenBalance={currentPoints}
              isLoadingTokens={isLoadingTokens}
              remainingCartTotal={inAppSection?.total?.money}
              showWarningMessage={isNil(inAppSection?.itemsTotal?.money)}
            />
          </SectionWrapper>
        )}
        <SectionWrapper title={intl.formatMessage({ id: 'label.shipTo' })}>
          <DeliveryDetails />
        </SectionWrapper>
        <SectionWrapper title={intl.formatMessage({ id: 'label.payment' })}>
          <PaymentDetails />
        </SectionWrapper>
        {!fbUser?.isAnonymous && (
          <SectionWrapper title={intl.formatMessage({ id: 'label.coupon' })}>
            <Coupon />
          </SectionWrapper>
        )}
        <SectionWrapper title={intl.formatMessage({ id: 'label.orderSummary' })}>
          <Summary
            inAppSection={inAppSection}
            setInAppSection={setInAppSection}
            onFailSummary={setIsPlaceOrderDisabled}
          />
        </SectionWrapper>
      </Content>
      <BottomNavigation
        onPlaceOrder={handlePlaceOrder}
        isLoading={isSubmitting}
        canPlaceOrder={!isPlaceOrderDisabled}
        isLoggedIn={!fbUser?.isAnonymous}
        hasPlaceOrder
      />
      <BottomDrawer isOpen={isDrawerOpen} closeDrawer={handleCloseDrawer}>
        <RedemptionProcess onClose={handleCloseDrawer} />
      </BottomDrawer>
      <StoryDialog isOpen={isDialogOpen} onClose={handleCloseDialog}>
        <InsufficientAmount insufficientAmount={insufficientAmount} onClose={handleCloseDialog} />
      </StoryDialog>
      <SigninInvitation />
      <DownloadAppOverlay />
    </Box>
  );
};

export default Checkout;
