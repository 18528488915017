import { Box, Button, Typography } from '@mui/material';
import { FC, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import TestCC from 'components/TestCC';
import { PaymentWrapper } from 'containers/Checkout/ChoosePayment/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { savePaymentToCart } from 'services/ShoppingCart';

import { CardInfo } from 'models/cardInfo.interface';

interface ChoosePaymentProps {
  payments: Record<string, CardInfo>;
  currentPayment: CardInfo;
  onAddPayment: () => void;
  onClose: () => void;
}

const ChoosePayment: FC<ChoosePaymentProps> = ({
  payments,
  currentPayment,
  onAddPayment,
  onClose,
}) => {
  const intl = useIntl();
  const { fbUser } = useContext(FbUserContext);
  const [selectedPayment, setSelectedPayment] = useState<CardInfo>(currentPayment);

  const handleSaveSelection = () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    savePaymentToCart(selectedPayment, fbUser);
    onClose();
  };

  return (
    <Box width="100%">
      <Typography variant="h1" mb={3}>
        {intl.formatMessage({ id: 'label.changePaymentMethod' })}
      </Typography>
      <Box display="flex" flexDirection="column" gap={1} mb={1}>
        {Object.values(payments).map((payment) => (
          <PaymentWrapper
            key={payment?.id}
            onClick={() => setSelectedPayment(payment)}
            isActive={selectedPayment?.id === payment?.id}
          >
            <Typography variant="subtitle2" fontWeight={400}>
              {intl.formatMessage(
                { id: 'label.paymentTypeEndingInDigits' },
                { paymentType: payment?.cardType, digits: payment?.redactedNumber?.replaceAll('*', '') }
              )}
            </Typography>
            {!payment?.liveMode && <TestCC />}
          </PaymentWrapper>
        ))}
      </Box>
      <Button fullWidth className="big" variant="outlined" onClick={onAddPayment}>
        {intl.formatMessage({ id: 'button.addAPaymentMethod' })}
      </Button>
      <Box display="flex" alignItems="center" gap={1} mt={3}>
        <Button fullWidth className="big" variant="outlined" onClick={onClose}>
          {intl.formatMessage({ id: 'button.close' })}
        </Button>
        <Button fullWidth className="big" variant="contained" onClick={handleSaveSelection}>
          {intl.formatMessage({ id: 'button.save' })}
        </Button>
      </Box>
    </Box>
  );
};

export default ChoosePayment;
