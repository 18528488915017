import { ProductInfo } from '~/models/product.interface';

import { DeliveryType, OrderSectionType, Visibility } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { Cart } from 'models/cart.interface';
import { Reward } from 'models/money.interface';
import { OrderSummaryResult } from 'models/order.interface';
import { User } from 'models/user.interface';
import { getTopLevelVariant } from 'utils/price';
import { multiply } from 'utils/reward';

export const valueOfProductInfo = (bounty: Bounty): ProductInfo => {
  const { product } = bounty;
  const productFlavor = getTopLevelVariant(product);

  return {
    id: '',
    bountyId: bounty.id || '',
    merchant: product?.merchant || {} as User,
    merchantId: product?.merchantId || '',
    productSku: productFlavor?.sku || null,
    name: product?.name || '',
    description: product?.description || '',
    kind: product?.kind || '',
    imageUrl: product?.imageUrl || '',
    msrp: productFlavor?.msrp,
    deliveryType: product?.deliveryType as DeliveryType || DeliveryType.Unknown,
    visibility: bounty?.visibility || Visibility.UserInternal,
    quantity: 1,
    addedAt: new Date().getTime(),
    price: productFlavor?.price?.mainPrice || null,
    onlineProductInfo: productFlavor?.onlineProductInfo || null,
  };
};

export const getShoppingCartPrices = (shoppingCart: Cart | null) => {
  const rewards: Reward[] = [];

  if (!shoppingCart || !shoppingCart.products || !Object.keys(shoppingCart.products).length) {
    return null;
  }

  Object.values(shoppingCart.products).forEach((product) => {
    const reward = product.price
      ? multiply(product.price, product.quantity)
      : null;

    if (reward) {
      rewards.push(reward);
    }
  });

  return rewards;
};

export const getFirstNonNullSection = (summary: OrderSummaryResult, types: OrderSectionType[]) => {
  if (!summary || !summary.summary) {
    return null;
  }

  const section = types.find((type) => summary.summary.sections[type]) || null;

  if (section) {
    return summary.summary.sections[section];
  }

  return null;
};
