import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Stats } from 'components/CampaignStats/index.styled';
import IconInfoWrapper from 'components/IconInfoWrapper';

import GeneratingTokens from 'assets/icons/GeneratingTokens';
import HourglassTop from 'assets/icons/HourglassTop';
import Verified from 'assets/icons/Verified';
import { SponsorState } from 'constants/enums';
import { Sponsor } from 'models/sponsor.interface';

interface CampaignStatsProps {
  sponsor?: Sponsor;
  hasBudget?: boolean;
  children?: ReactNode;
}

const CampaignStats:FC<CampaignStatsProps> = ({
  sponsor,
  hasBudget = false,
  children,
}) => (
  <Box display="flex" flexDirection="column" gap={1.5}>
    <Stats>
      {sponsor && (
        <IconInfoWrapper
          isDark
          icon={sponsor?.state !== SponsorState.accepted
            ? <HourglassTop size={22} />
            : <Verified size={22} />}
          sx={{ mt: 0 }}
        />
      )}
      {hasBudget && (
        <IconInfoWrapper isDark icon={<GeneratingTokens color="white" />} sx={{ mt: 0 }} />
      )}
    </Stats>
    {children}
  </Box>
);

export default CampaignStats;
