import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import DownloadAppOverlay from 'components/DownloadAppOverlay';
import LoadingStory from 'components/LoadingStory';
import SigninInvitation from 'containers/SigninInvitation';
import BottomNavigation from 'containers/Wallet/BottomNavigation';
import CurrencyDetails from 'containers/Wallet/CurrencyDetails';
import { Content } from 'containers/Wallet/index.styled';
import SignInOverlay from 'containers/Wallet/SignInOverlay';
import { sortByTotalAmount } from 'containers/Wallet/utils';
import { FbUserContext } from 'context/FbUserContext';

import { getAccountBalance, getAccountCurrencies } from 'services/Account';

import { PointCurrency } from 'constants/enums';
import { UserCurrency } from 'models/currency.interface';
import { handleApiErrors } from 'utils/error';

const Wallet = () => {
  const intl = useIntl();
  const { fbUser } = useContext(FbUserContext);
  const [currencies, setCurrencies] = useState<UserCurrency[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (fbUser) {
      fetchCurrencies();
    }
  }, [fbUser]);

  const fetchCurrencies = async () => {
    setIsLoading(true);
    try {
      const { data } = await getAccountBalance();
      const { available, onHold } = data || {};

      let formattedCurrencies: UserCurrency[] = [];
      const systemTokens = {
        imageUrl: '',
        name: intl.formatMessage({ id: 'label.systemTokens' }),
        code: PointCurrency.Systkn,
        available: available?.points?.[PointCurrency.Systkn] || null,
        locked: null,
        totalAmount: (available?.points?.[PointCurrency.Systkn]?.amount || 0)
          + (onHold?.points?.[PointCurrency.Systkn]?.amount || 0),
      };
      const hasSystemTokens = systemTokens?.available || onHold?.points?.[PointCurrency.Systkn];
      const formattedSystemTokens = onHold?.points?.[PointCurrency.Systkn]
        ? [systemTokens, { ...systemTokens, available: null, locked: onHold?.points?.[PointCurrency.Systkn] }]
        : [systemTokens];
      const codes = [
        ...(available?.points ? Object.keys(available?.points)?.map((key) => key) : []),
        ...(onHold?.points ? Object.keys(onHold?.points)?.map((key) => key) : []),
      ];
      const filteredCodes = codes?.filter((code) => code !== PointCurrency.Systkn)?.join(',');

      if (!filteredCodes?.length) {
        setCurrencies(hasSystemTokens ? formattedSystemTokens : []);
        return;
      }

      const response = await getAccountCurrencies(filteredCodes);

      if (response?.data?.list?.length > 0) {
          response?.data?.list?.forEach((token) => {
          const foundAvailable = available?.points
            ? available?.points?.[token?.meta?.code]
            : null;
          const foundHold = onHold?.points
            ? onHold?.points?.[token?.meta?.code]
            : null;

          const currency = {
            imageUrl: token?.meta?.imageUrl,
            name: token?.meta?.name,
            code: token?.meta?.code,
            issuerId: token?.meta?.userId,
            available: foundAvailable,
            locked: null,
            totalAmount: (foundAvailable?.amount || 0) + (foundHold?.amount || 0),
          };

          if (foundAvailable?.amount) {
            formattedCurrencies.push(currency);
          }

          if (token?.meta?.userId === fbUser?.uid && foundHold) {
            formattedCurrencies.push({
              ...currency,
              available: null,
              locked: token?.meta?.userId === fbUser?.uid ? foundHold : null,
            });
          }
        });
      }

      const newCurrencies = hasSystemTokens
        ? [...formattedSystemTokens, ...formattedCurrencies]
        : formattedCurrencies;

      setCurrencies(sortByTotalAmount(newCurrencies));
    } catch (error) {
      handleApiErrors(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingStory />;
  }

  return (
    <Box height="100%" position="relative">
      <Content position="relative" className="hidden-scroll">
        <Typography variant="h1" textAlign="center" color="textPrimary">
          {intl.formatMessage({ id: 'label.wallet' })}
        </Typography>
        {currencies?.length > 0 ? (
          <Box display="flex" flexDirection="column" gap={2}>
            {currencies?.map((currency) => (
              <CurrencyDetails key={currency?.code} currency={currency} />
            ))}
          </Box>
        ) : (
          <Box height="100%" display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h2" color="textPrimary" textAlign="center" maxWidth="475px">
              {intl.formatMessage({ id: 'label.youCurrentlyHaveNoTokens' })}
            </Typography>
          </Box>
        )}
      </Content>
      <BottomNavigation />
      <SigninInvitation />
      <SignInOverlay />
      <DownloadAppOverlay />
    </Box>
  );
};

export default Wallet;
