import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import { xContentPadding } from 'assets/theme/customSpacing';

export const CustomDrawer = styled(MuiDrawer)<{ width: number }>(({ theme, width }) => ({
  display: 'flex',
  alignItems: 'center',

  '& .MuiPaper-root': {
    width: `${width - (2 * xContentPadding)}px`,
    borderTopRightRadius: '12px',
    borderTopLeftRadius: '12px',
    margin: 'auto',
    padding: theme.spacing(4, 2),
    maxHeight: '90%',

    '& .content': {
      overflowY: 'auto',
    },
  },
}));
