import { alpha, Box, Button, Typography } from '@mui/material';
import MuiIconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.2),
  zIndex: 1000,
  color: theme.palette.common.white,
  fontSize: '1.75rem',
  padding: theme.spacing(1.5),
  width: '42px',
  height: '42px',
}));

const getBackgroundColor = (theme: any, isDisabled?: boolean, isHover?: boolean, bkgColor?: string) => {
  if (bkgColor) {
    if (bkgColor === 'warning') {
      return theme.palette.warning.main;
    }

    if (bkgColor === 'primary') {
      return isHover ? theme.palette.primary.dark : theme.palette.primary.main;
    }
  }

  if (isHover) {
    return isDisabled ? alpha(theme.palette.common.white, 0.1) : alpha(theme.palette.common.white, 0.2);
  }

  return isDisabled ? alpha(theme.palette.common.white, 0.1) : alpha(theme.palette.common.white, 0.2);
};

const getFlashAnimation = (theme: any, isHover: boolean) => ({
  animation: 'flash 0.5s ease-in-out 3 forwards',
  '@keyframes flash': {
    '0%, 100%': { backgroundColor: isHover ? theme.palette.primary.dark : theme.palette.primary.main },
    '50%': { backgroundColor: theme.palette.common.black },
  },
});

export const ActionButton = styled(
  IconButton,
  { shouldForwardProp: (props) => props !== 'isDisabled' && props !== 'bkgColor' },
)<{ isDisabled?: boolean; bkgColor?: string }>(({ theme, isDisabled, bkgColor }) => ({
  '&.MuiIconButton-root:hover': {
    backgroundColor: getBackgroundColor(theme, isDisabled, true, bkgColor),
    ...(bkgColor === 'primary' ? getFlashAnimation(theme, true) : {}),
  },
  backgroundColor: getBackgroundColor(theme, isDisabled, false, bkgColor),
  ...(bkgColor === 'primary' ? getFlashAnimation(theme, false) : {}),
  cursor: isDisabled ? 'auto' : 'pointer',
  position: 'relative',
}));

export const SeriesButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  borderRadius: '22px',
  height: '42px',
  padding: theme.spacing(1, 1.5, 1, 2),
  zIndex: 1000,
  [theme.breakpoints.down(425)]: {
    padding: 0,
    width: '42px',
    minWidth: 'initial',

    '& .MuiTypography-root': {
      display: 'none',
    },

    '& .MuiButton-icon': {
      margin: 0,
    },
  },
}));

export const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: '24px',
  padding: theme.spacing(0, 2),
  minHeight: '42px',
  zIndex: 1000,
}));

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  borderRadius: '32px',
  backgroundColor: theme.palette.background.main,
  padding: theme.spacing(1, 1.5),
}));

export const ViewLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: 0.5,
  alignItems: 'center',
  textTransform: 'uppercase',
  color: theme.palette.common.white,
}));
