import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  height: '100px',
  borderBottomRightRadius: '10px',
  borderBottomLeftRadius: '10px',

  '& .MuiButton-root': {
    minWidth: '120px',
  },

  [theme.breakpoints.down('sm')]: {
    '& .MuiButton-root': {
      minWidth: 'initial',
    },
  }
}));
