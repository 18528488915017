import { Box, Divider, Rating, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import Review from 'containers/ProductDetails/Review';
import SoldBy from 'containers/ProductDetails/SoldBy';

import { Bounty } from 'models/bounty.interface';
import { Comment } from 'models/comment.interface';
import { getProductRating } from 'utils/rating';

import { RatingWrapper, Root } from './index.styled';

interface DescriptionTabProps {
  comments: Comment[];
  bounty?: Bounty;
}

const DescriptionTab: FC<DescriptionTabProps> = ({
  bounty,
  comments,
}) => {
  const intl = useIntl();
  const productRating = getProductRating(comments);
  const merchant = bounty?.product?.merchant;

  return (
    <Root>
      <Typography color="textPrimary" variant="h2" fontWeight={400}>
        {bounty?.product?.description}
      </Typography>
      <RatingWrapper>
        <Box display="flex" alignItems="center" gap={1}>
          <Rating name="productRating" value={productRating} readOnly />
          {comments?.length > 0 && (
            <Typography variant="subtitle2" fontWeight={400} color="warning.main">
              ({comments?.length})
            </Typography>
          )}
        </Box>
        {merchant && <SoldBy user={merchant} />}
      </RatingWrapper>
      {comments?.length > 0 && (
        <>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle2" mb={1}>
            {intl.formatMessage({ id: 'label.recentReviews' })}
          </Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {Object.values(comments).map((comment) => (
              <Review key={comment?.sentAt} review={comment} />
            ))}
          </Box>
        </>
      )}
    </Root>
  );
};

export default DescriptionTab;
