export const HOME_ROUTE = '/';
export const CAMPAIGN_VIEW = '/campaigns/:campaignId';
export const ICONS_ROUTE = '/icons';
export const VISIT_APP = '/visit';
export const SERIES_DETAILS_ROUTE = '/series/:campaignId';
export const SERIES_RANKINGS_ROUTE = '/series/:campaignId/rankings';
export const USER_DETAILS_ROUTE = '/users/:userId';
export const PRODUCT_DETAILS_ROUTE = '/products/:bountyId';
export const CHECKOUT_ROUTE = '/checkout';
export const WALLET_ROUTE = '/wallet';
