import {
  FormLabel, Typography, SelectProps, MenuItem, FormHelperText, FormControl, Select, Box, Tooltip,
} from '@mui/material';
import { FC } from 'react';

import InfoIcon from 'assets/icons/InfoIcon';
import { Option } from 'models/option.interface';

import { StyledWrapper } from './index.styled';

interface DropdownFieldProps {
  options: Array<Option>;
  helperText?: string;
  placeholder?: string;
  tooltip?: string;
}

const DropdownField:FC<SelectProps & DropdownFieldProps> = (props) => {
  const {
    name,
    required,
    fullWidth,
    label,
    options,
    error,
    helperText,
    placeholder,
    tooltip,
    ...remainingProps
  } = props;

  return (
    <StyledWrapper hasError={error}>
      <FormControl component="div" fullWidth={fullWidth} error={error}>
        {label && (
          <Box display="flex" alignItems="center" gap={1} mb={0.5}>
            <FormLabel required={required}>
              <Typography component="span" variant="body2" color="textPrimary">
                {label}
              </Typography>
            </FormLabel>
            {tooltip && (
              <Tooltip arrow title={tooltip} placement="top">
                <Box display="flex" alignItems="center">
                  <InfoIcon size={18} />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
        <Select
          {...remainingProps}
          name={name}
          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
          error={error}
        >
          {placeholder && (
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
          )}
          {options.map(({ label, value }, index: number) => (
            <MenuItem value={value} key={`${index}-${value}`}>
              {label}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </FormControl>
    </StyledWrapper>
  );
};

export default DropdownField;
