import {
  FormControl, FormLabel, TextField, TextFieldProps, Tooltip, Typography, Box, FormHelperText,
} from '@mui/material';
import { FC } from 'react';

import InfoIcon from 'assets/icons/InfoIcon';

interface InputFieldProps {
  tooltip?: string;
}

const InputField:FC<TextFieldProps & InputFieldProps> = ({
  name,
  required,
  fullWidth,
  label,
  tooltip,
  size = 'small',
  variant = 'filled',
  error = false,
  helperText = '',
  ...remainingProps
}) => (
  <FormControl component="div" fullWidth={fullWidth} error={error}>
    {label && (
      <Box display="flex" alignItems="center" gap={0.5} mb={0.5}>
        <FormLabel required={required}>
          <Typography component="span" variant="body2" color="textPrimary">
            {label}
          </Typography>
        </FormLabel>
        {tooltip && (
          <Tooltip arrow title={tooltip} placement="top">
            <Box display="flex" alignItems="center">
              <InfoIcon size={18} />
            </Box>
          </Tooltip>
        )}
      </Box>
    )}
    <TextField
      {...remainingProps}
      variant={variant}
      size={size}
      name={name}
      error={error}
    />
    {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
  </FormControl>
);

export default InputField;
