import { Box, Divider, Typography, useTheme } from '@mui/material';
import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { CardMedia, Root, Button } from 'containers/Wallet/CurrencyDetails/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import Lock from 'assets/icons/Lock';
import LockOpen from 'assets/icons/LockOpen';
import Storefront from 'assets/icons/Storefront';
import EmptyImage from 'assets/images/imagePlaceholder.png';
import TokenCoin from 'assets/images/token-coin.svg';
import { USER_DETAILS_ROUTE } from 'constants/clientRoutes';
import { PointCurrency } from 'constants/enums';
import { UserCurrency } from 'models/currency.interface';
import { formatRoute } from 'utils/formatters';

interface CurrencyDetailsProps {
  currency: UserCurrency;
}

const CurrencyDetails: FC<CurrencyDetailsProps> = ({ currency }) => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const { fbUser } = useContext(FbUserContext);
  const isSystkn = currency?.code === PointCurrency.Systkn;
  const imageUrl = isSystkn ? TokenCoin : currency.imageUrl;
  const amount = currency?.available?.amount || currency?.locked?.amount || 0;
  const isDark = !!currency?.locked?.amount && currency?.locked?.amount > 0;

  const handleVisitShop = () => navigate(formatRoute(USER_DETAILS_ROUTE, { userId: currency?.issuerId }));

  return (
    <Root isDark={isDark}>
      <Box display="flex" gap={1.5} my={2.5} mx={1.5}>
        <CardMedia component="img" src={imageUrl || EmptyImage} />
        <div>
          <Typography variant="title1" color="textPrimary" mb={0.5}>
            {amount?.toLocaleString()}
          </Typography>
          <Typography variant="h1" fontWeight={400} className="subtitle">
            {`${currency.name} (${currency.code})`}
          </Typography>
        </div>
      </Box>
      {currency.issuerId !== fbUser?.uid && !isSystkn
        ? (
          <Button
            className="big"
            variant="contained"
            onClick={handleVisitShop}
            fullWidth
            startIcon={<Storefront color={theme.palette.common.white} />}
          >
            {intl.formatMessage({ id: 'button.visitShop' })}
          </Button>
        ) : (
          <div>
            <Divider />
            <Box display="flex" alignItems="center" gap={2.5} ml={2.5} mr={1.5} my={2.5}>
              {isDark ? <Lock /> : <LockOpen />}
              <Typography variant="h1" fontWeight={400} className="subtitle" fontStyle="italic">
                {intl.formatMessage({ id: isDark ? 'label.lockedInCampaigns' : 'label.readyToUse' })}
              </Typography>
            </Box>
          </div>
        )}
    </Root>
  );
};

export default CurrencyDetails;
