import { Button, FormLabel, SelectChangeEvent, Typography } from '@mui/material';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { StripeForm, InputField, DropdownField } from 'containers/AddCreditCard/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { saveCreditCard } from 'services/Payment';

import { COUNTRIES_OPTIONS } from 'constants/countries';
import { NAME_REGEX } from 'constants/regex';
import { CardInfo } from 'models/cardInfo.interface';
import { cardTypeFromString } from 'utils/creditCard';

const options = {
  style: {
    base: {
      fontSize: '18px',
    },
  },
};

interface AddCreditCardProps {
  onSuccess: (card: CardInfo) => void;
}

const AddCreditCard:FC<AddCreditCardProps> = ({ onSuccess }) => {
  const intl = useIntl();
  const stripe = useStripe();
  const elements = useElements();
  const { fbUser } = useContext(FbUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cardInfo, setCardInfo] = useState<{ cardholderName: string; countryCode: string }>({
    cardholderName: '',
    countryCode: '',
  });

  const handleSubmit = async (event: MouseEvent | FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    if (!cardInfo?.cardholderName || !NAME_REGEX.test(cardInfo?.cardholderName)) {
      toast.error(intl.formatMessage({ id: 'error.missingCardholderName' }));
      return;
    }

    if (!cardInfo?.countryCode) {
      toast.error(intl.formatMessage({ id: 'error.missingCountry' }));
      return;
    }

    if (!stripe || !elements) {
      toast.error(intl.formatMessage({ id: 'error.stripe' }));
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      toast.error(intl.formatMessage({ id: 'error.stripe' }));
      return;
    }

    setIsSubmitting(true);

    const payload = {
      name: cardInfo.cardholderName,
      'address_country': cardInfo?.countryCode,
    };
    const { token, error } = await stripe.createToken(cardElement, payload);

    if (error) {
      toast.error(intl.formatMessage({ id: 'error.stripe' }));
      setIsSubmitting(false);
      return;
    }

    const { card } = token || {};
    const cardType = cardTypeFromString(card?.brand);
    if (card && cardType) {
      const newCard = {
        cardType,
        cardholderName: cardInfo?.cardholderName,
        countryCode: cardInfo?.countryCode,
        redactedNumber: card.last4,
        expMonth: card.exp_month,
        expYear: card.exp_year,
      };

      const result = await saveCreditCard({
        userId: fbUser.uid,
        token,
        cardInfo: newCard,
      });

      if (onSuccess) {
        onSuccess(result);
      }

      setIsSubmitting(false);
    }
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setCardInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelect = ({ target: { name, value } }: SelectChangeEvent<unknown>) => {
    setCardInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <StripeForm onSubmit={handleSubmit}>
      <Typography variant="h2" mb={3}>
        {intl.formatMessage({ id: 'label.addCard' })}
      </Typography>

      <div className="element">
        <InputField
          name="cardholderName"
          variant="outlined"
          onChange={handleChange}
          value={cardInfo?.cardholderName}
          fullWidth
          size="small"
          label={intl.formatMessage({ id: 'label.cardholderName' })}
          required
        />
      </div>

      <div className="element">
        <DropdownField
          name="countryCode"
          variant="outlined"
          onChange={handleSelect}
          options={COUNTRIES_OPTIONS}
          value={cardInfo?.countryCode}
          fullWidth
          size="small"
          label={intl.formatMessage({ id: 'label.country' })}
          required
        />
      </div>

      <div className="element">
        <FormLabel required>
          <Typography component="span" color="textPrimary" variant="body2">
            {intl.formatMessage({ id: 'label.cardNumber' })}
          </Typography>
        </FormLabel>
        <CardNumberElement options={options}/>
      </div>

      <div className="element">
        <FormLabel required>
          <Typography component="span" color="textPrimary" variant="body2">
            {intl.formatMessage({ id: 'label.expirationDate' })}
          </Typography>
        </FormLabel>
        <CardExpiryElement options={options}/>
      </div>

      <div className="element">
        <FormLabel required>
          <Typography component="span" color="textPrimary" variant="body2">
            {intl.formatMessage({ id: 'label.cvc' })}
          </Typography>
        </FormLabel>
        <CardCvcElement options={options}/>
      </div>

      <Button
        className="big"
        fullWidth
        variant="contained"
        type="submit"
        disabled={!stripe || isSubmitting}
        sx={{ mt: 1 }}
      >
        {intl.formatMessage({ id: 'button.save' })}
      </Button>
    </StripeForm>
);
};

export default AddCreditCard;
