import { BountyType, SponsorState } from 'constants/enums';
import { LabelsMappingInterface } from 'models/label.interface';

export const SPONSORSHIP_STATUS_LABELS = {
  [SponsorState.sent]: 'label.pending',
  [SponsorState.accepted]: 'label.endorsed',
  [SponsorState.none]: 'label.pending',
  [SponsorState.unknown]: 'label.pending',
  [SponsorState.rejected]: 'label.declined',
  [SponsorState.notAssigned]: 'label.pending',
} as LabelsMappingInterface;

export const BOUNTY_TYPE_LABELS = {
  [BountyType.Funding]: 'label.funding',
  [BountyType.Lottery]: 'label.lottery',
  [BountyType.MCQ]: 'label.mcq',
  [BountyType.Poll]: 'label.poll',
  [BountyType.TMOB_Challenge]: 'label.tmob_challenge',
  [BountyType.Trivia]: 'label.trivia',
} as LabelsMappingInterface;
