import { Rating, Typography } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { USER_DETAILS_ROUTE } from 'constants/clientRoutes';
import { DateFormats } from 'constants/enums';
import { Comment } from 'models/comment.interface';
import { Reward } from 'models/money.interface';
import { User } from 'models/user.interface';
import { formatDate, formatRoute } from 'utils/formatters';

interface ReviewProps {
  review: Comment;
}

const Review: FC<ReviewProps> = ({ review }) => {
  const navigate = useNavigate();

  const getRewardValue = (reward?: Reward) => (
    (reward && reward.points && reward.points.RATING && reward.points.RATING.amount) || 0
  );

  const getAuthorName = (creator: User) => creator?.name || '';

  const handleSeeCreator = () => {
    navigate(formatRoute(USER_DETAILS_ROUTE, { userId: review?.creator?.id }));
  };

  return (
    <div>
      <Rating
        readOnly
        name="product-rating"
        size="small"
        value={getRewardValue(review?.reward)}
      />
      <Typography variant="subtitle2">
        "{review?.text}"
      </Typography>
      {review?.creator && (
        <Typography variant="subtitle2" fontWeight={400} mt={0.5} onClick={handleSeeCreator} className="pointer">
          {review?.sentAt
            ? `- ${getAuthorName(review?.creator)}, ${formatDate(review?.sentAt, DateFormats.longDate)}`
            : `- ${getAuthorName(review?.creator)}`}
        </Typography>
      )}
    </div>
  );
};

export default Review;
