import { off, onValue, orderByPriority, query } from 'firebase/database';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Logo from 'components/Logo';
import { FbUserContext } from 'context/FbUserContext';
import { Container, HeaderWrapper, Root, } from 'layouts/CampaignViewerLayout/index.styled';
import CustomSuspense from 'routes/CustomSuspense';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStoryDimensions from 'hooks/useStoryDimensions';
import { getShoppingCartRef } from 'services/FirebaseRefs';
import { getMyShoppingCartId } from 'services/ShoppingCart';
import { setCart, setStatus, setTotalProductsQuantity } from 'store/cart/cartSlice';
import { totalProductsQuantitySelector } from 'store/cart/selectors';

import { ApiStatus } from 'constants/enums';
import { ProductInfo } from 'models/product.interface';
import { getBackgroundColor } from 'utils/theme';

const CampaignViewerLayout = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { width, height } = useStoryDimensions();
  const [searchParams] = useSearchParams();
  const { fbUser } = useContext(FbUserContext);
  const embedded = searchParams?.get('embedded') || '';
  const backgroundColor = getBackgroundColor(searchParams);
  const totalProductsQuantity = useAppSelector(totalProductsQuantitySelector);

  const calculateTotalQuantity = (items: Record<string, ProductInfo>) => {
    const total = items
      ? Object.values(items).reduce((acc, curr) => (acc + curr.quantity), 0)
      : 0;

    if (totalProductsQuantity !== total) {
      dispatch(setTotalProductsQuantity(total));
    }
  };

  useEffect(() => {
    if (fbUser?.uid) {
      dispatch(setStatus(ApiStatus.loading));
      const refQuery = query(getShoppingCartRef(getMyShoppingCartId(fbUser), fbUser), orderByPriority());

      onValue(
        refQuery,
        (dataSnapshot) => {
          const cart = dataSnapshot.val() || null;
          calculateTotalQuantity(cart?.products);
          dispatch(setCart(cart));
          dispatch(setStatus(ApiStatus.idle));
        },
        () => {
          toast.error(intl.formatMessage({ id: 'error.cannotRetrieveCart' }));
        },
      );
    }

    return () => {
      if (fbUser?.uid) {
        const refQuery = query(getShoppingCartRef(getMyShoppingCartId(fbUser), fbUser), orderByPriority());
        off(refQuery);
      }
    };
  }, [fbUser]);

  return (
    <Root backgroundColor={backgroundColor}>
      {!embedded && (
        <HeaderWrapper>
          <Logo />
        </HeaderWrapper>
      )}
      <Container style={{ width, height }}>
        <CustomSuspense>
          <Outlet />
        </CustomSuspense>
      </Container>
    </Root>
  );
};

export default CampaignViewerLayout;
