import { createSelector } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

import { RootState } from 'store/index';

export const isInvitationOpenSelector = (state: RootState): boolean => state.signinInvitation.isInvitationOpen;
export const isSignInOpenSelector = (state: RootState): boolean => state.signinInvitation.isSignInOpen;
export const isSignInOverlayOpenSelector = (state: RootState): boolean => state.signinInvitation.isSignInOverlayOpen;
export const typeSelector = (state: RootState): string => state.signinInvitation.type;
export const valuesSelector = (state: RootState): Record<string, string> => state.signinInvitation.values || {};
export const isSubmittingSelector = (state: RootState): boolean => state.signinInvitation.isSubmitting || false;
export const authTypeSelector = (state: RootState): string => state.signinInvitation.authType || '';
export const loginMethodSelector = (state: RootState): string => state.signinInvitation.loginMethod || '';
export const dataSelector = (state: RootState): Record<string, string> => state.signinInvitation.data || {};
export const tempUserSelector = (state: RootState): User|undefined => state.signinInvitation.tempUser || undefined;
export const claimTokensSelector = (state: RootState): boolean|undefined => state.signinInvitation.claimTokens || false;

export const contentSelector = createSelector(
  typeSelector,
  valuesSelector,
  (type, values) => {
    if (!type) {
      return {
        content: null,
        values: {},
      };
    }

    const content = CONTENT_BY_TYPE[type];
    return {
      content: content || CONTENT_BY_TYPE.generic,
      values,
    };
  },
);

interface ContentProps {
  title: string;
  message: string;
}

const CONTENT_BY_TYPE: Record<string, ContentProps> = {
  funding: {
    title: 'label.signinInvitation.fundraiser.title',
    message: 'label.signinInvitation.fundraiser.message',
  },
  poll: {
    title: 'label.signinInvitation.poll.title',
    message: 'label.signinInvitation.poll.message',
  },
  lottery: {
    title: 'label.signinInvitation.lottery.title',
    message: 'label.signinInvitation.lottery.message',
  },
  mcq: {
    title: 'label.signinInvitation.mcq.title',
    message: 'label.signinInvitation.mcq.message',
  },
  challenge: {
    title: 'label.signinInvitation.challenge.title',
    message: 'label.signinInvitation.challenge.message',
  },
  triviaSubbounty: {
    title: 'label.signinInvitation.triviaSubbounty.title',
    message: 'label.signinInvitation.triviaSubbounty.message',
  },
  comment: {
    title: 'label.signinInvitation.comment.title',
    message: 'label.signinInvitation.comment.message',
  },
  like: {
    title: 'label.signinInvitation.like.title',
    message: 'label.signinInvitation.like.message',
  },
  menu: {
    title: 'label.signinInvitation.menu.title',
    message: 'label.signinInvitation.menu.message',
  },
  generic: {
    title: 'label.signinInvitation.generic.title',
    message: 'label.signinInvitation.generic.message',
  },
  reward: {
    title: 'label.signinInvitation.reward.title',
    message: 'label.signinInvitation.reward.message',
  },
};
