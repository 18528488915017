import { AxiosResponse } from 'axios';

import { runGetApiRequest } from 'services/Api';

import { ACCOUNT_AVAILABLE_AMOUNT, ACCOUNT_CURRENCIES_API, CURRENCY_DETAILS_ROUTE } from 'constants/apiRoutes';
import { AmountTmp, CustomCurrency } from 'models/currency.interface';
import { formatRoute } from 'utils/formatters';

export const getCurrencyDetails = (code: string) => (
  runGetApiRequest(formatRoute(CURRENCY_DETAILS_ROUTE, { code }))
);

export const getAccountBalance = (currency?: string): Promise<AxiosResponse<AmountTmp>> => (
  runGetApiRequest(ACCOUNT_AVAILABLE_AMOUNT, currency ? { currency }: {})
);

export const getAccountCurrencies = (codes?: string): Promise<AxiosResponse<{ list: CustomCurrency[] }>> => (
  runGetApiRequest(ACCOUNT_CURRENCIES_API, codes ? { codes } : {})
);
