import { customArray } from 'country-codes-list';

const excludedCountries = ['AQ', 'BQ', 'EH'];

/** Returns an object of the form
 * {
 *   0: {label: 'Andorra', code: 'AD', phoneCode: '+376'},
 *   1: {label: 'Afghanistan', code: 'AF', phoneCode: '+93'},
 *   ...
 * }
 */
export const COUNTRIES_LIST: Record<string, string>[] = customArray(
  {
    label: '{countryNameEn}',
    code: '{countryCode}',
    phoneCode: '+{countryCallingCode}',
  },
)
  .filter((item) => {
    const isExcluded = excludedCountries.some((element) => element === item.code);
    return !isExcluded;
  })
  .sort((a, b) => a.label.localeCompare(b.label));

export const COUNTRIES_OPTIONS = COUNTRIES_LIST.map(({ label, code }) => ({ label, value: code }));
