import { Box, CardMedia, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import CampaignStats from 'components/CampaignStats';
import { HeaderSection, InfoSection, Root } from 'containers/MerchantProfile/CampaignCard/index.styled';
import { getCampaignCover } from 'containers/MerchantProfile/utils';

import { CAMPAIGN_VIEW } from 'constants/clientRoutes';
import { BOUNTY_TYPE_LABELS } from 'constants/labels';
import { Bounty } from 'models/bounty.interface';
import { CampaignDescription } from 'models/description.interface';
import { formatRoute } from 'utils/formatters';
import { parseEncryptedDescription } from 'utils/yaml';

interface CampaignCardProps {
  bounty: Bounty;
}

const CampaignCard:FC<CampaignCardProps> = ({ bounty }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const description = bounty?.description
    ? parseEncryptedDescription<CampaignDescription>(bounty?.description)
    : {};
  const { sponsor } = useMemo(() => {
    const sponsor = bounty?.sponsors && Object.values(bounty?.sponsors)?.[0];
    return { sponsor, isSponsorTheCreator: sponsor?.user?.id === bounty?.creator?.id };
  }, [bounty?.sponsors]);
  const { coverImage, showEmptyState } = getCampaignCover(bounty);
  const label = description?.label ? BOUNTY_TYPE_LABELS?.[description?.label] : '';

  const openDetails = () => {
    navigate(formatRoute(CAMPAIGN_VIEW, { campaignId: bounty.id }), { replace: true });
  };

  return (
    <Root backgroundColor={showEmptyState ? bounty?.theme?.colorScheme?.colors?.[0]?.val : ''}>
      <Box
        role="presentation"
        position="relative"
        className="pointer"
        onClick={openDetails}
      >
        <CardMedia
          component="img"
          alt={bounty.title}
          src={coverImage}
          sx={{ opacity: showEmptyState ? 0.05 : 1 }}
        />

        <HeaderSection>
          <CampaignStats sponsor={sponsor} hasBudget={!isNil(description?.budgetAmount)}>
            {showEmptyState && label && (
              <Typography variant="h1">
                {intl.formatMessage({ id: label })}
              </Typography>
            )}
          </CampaignStats>
        </HeaderSection>

        <InfoSection>
          {bounty.title && (
            <Typography variant="h1" gutterBottom>
              {bounty.title}
            </Typography>
          )}
        </InfoSection>
      </Box>
    </Root>
  );
};

export default CampaignCard;
