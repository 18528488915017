import { Skeleton } from '@mui/material';

import { Root } from 'containers/MerchantProfile/CampaignCard/index.styled';

const CampaignCardSkeleton = () => (
  <Root>
    <Skeleton className="skeletonImage" animation="wave" variant="rounded" width="100%" />
  </Root>
);

export default CampaignCardSkeleton;
