import { isEmpty } from 'lodash';

import { DEFAULT_ADDRESS } from 'containers/Checkout/constants';

import { AddressType, DeliveryType, InvalidReason } from 'constants/enums';
import { Address, CreateAddress } from 'models/address.interface';
import { ProductInfo, InvalidProduct } from 'models/product.interface';
import { checkValidPhone } from 'utils/phoneUtils';

export const getInitialAddress = (address?: Address): CreateAddress => ({
  name: address?.name || DEFAULT_ADDRESS.name,
  line1: address?.line1 || DEFAULT_ADDRESS.line1,
  line2: address?.line2 || DEFAULT_ADDRESS.line2,
  phoneNumber: address?.phoneNumber || DEFAULT_ADDRESS.phoneNumber,
  countryCode: address?.countryCode || DEFAULT_ADDRESS.countryCode,
  state: address?.state || DEFAULT_ADDRESS.state,
  city: address?.city || DEFAULT_ADDRESS.city,
  postalCode: address?.postalCode || DEFAULT_ADDRESS.postalCode,
  type: AddressType.Shipping,
});

export const validateAddress = (address: Address) => {
  const errors: Record<string, string> = {};

  if (!address?.name) {
    errors.name = 'error.mandatoryField';
  }

  if (!address?.line1) {
    errors.line1 = 'error.mandatoryField';
  }

  if (!address?.countryCode) {
    errors.countryCode = 'error.mandatoryField';
  }

  if (address?.phoneNumber && !checkValidPhone(address?.phoneNumber)) {
    errors.phoneNumber = 'error.invalidPhoneNumber';
  }

  if (!address?.state) {
    errors.state = 'error.mandatoryField';
  }

  if (!address?.city) {
    errors.city = 'error.mandatoryField';
  }

  if (!address?.postalCode) {
    errors.postalCode = 'error.mandatoryField';
  }

  return { errors, hasErrors: !isEmpty(errors) };
};

export const checkProductsRequireShipping = (products: Record<string, ProductInfo>) => {
  if (isEmpty(products)) {
    return false;
  }

  return Object.values(products)?.some(({ deliveryType }) => deliveryType !== DeliveryType.NoDelivery);
};

export const getMainInvalidReason = (invalidProducts?: InvalidProduct[]) => {
  if (!invalidProducts || invalidProducts.length < 0) {
    return null;
  }

  const hasOutOfStock = invalidProducts?.some(({ reasons }) => reasons?.some((reason) => (
    reason === InvalidReason.InsufficientProductAmount
  )));

  if (hasOutOfStock) {
    return InvalidReason.InsufficientProductAmount;
  }

  return InvalidReason.NoDeliveryToCountry;
};
