import { AxiosResponse } from 'axios';

import { runGetApiRequest } from 'services/Api';

import { OPEN_EXCHANGE_TOKENS_ROUTE } from 'constants/apiRoutes';
import { Currency } from 'constants/enums';
import { ExchangeRate } from 'models/currency.interface';
import { formatRoute } from 'utils/formatters';

export const checkConversion = (
  currencyFrom: string, amountFrom: string, currencyTo: Currency
): Promise<AxiosResponse<{ rates: Record<Currency, ExchangeRate> }>> => {
  const filters = { amountFrom, currencyTo };
  const endpoint = formatRoute(OPEN_EXCHANGE_TOKENS_ROUTE, { currencyFrom });
  
  return runGetApiRequest(endpoint, filters);
};
