import { Box, IconButton, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import ProductPrice from 'components/ProductPrice';
import {
  CardMedia,
  Description,
  EmptyBackground,
} from 'containers/Checkout/ProductDetails/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch } from 'hooks/useRedux';
import { updateProductQuantity } from 'services/ShoppingCart';
import { setTotalProductsQuantity } from 'store/cart/cartSlice';

import Add from 'assets/icons/Add';
import Panorama from 'assets/icons/Panorama';
import Remove from 'assets/icons/Remove';
import { PRODUCT_DETAILS_ROUTE, USER_DETAILS_ROUTE } from 'constants/clientRoutes';
import { ProductInfo } from 'models/product.interface';
import { formatRoute } from 'utils/formatters';

enum ActionType {
  increase = 'increase',
  decrease = 'decrease'
}

interface ProductDetailsProps {
  product: ProductInfo;
  errorMessage: ReactNode | null;
}

const ProductDetails: FC<ProductDetailsProps> = ({ product, errorMessage }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fbUser } = useContext(FbUserContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(425));

  const handleChangeQuantity = (actionType: ActionType) => {
    const newQuantity = actionType === ActionType.increase
      ? product?.quantity + 1
      : product?.quantity - 1;

    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    dispatch(setTotalProductsQuantity(newQuantity));
    updateProductQuantity({ productInfo: product, newQuantity, user: fbUser });
  };

  const handleSeeDetails = () => (
    navigate(formatRoute(PRODUCT_DETAILS_ROUTE, { bountyId: product?.bountyId }))
  );

  const handleSeeUser = () => (
    navigate(formatRoute(USER_DETAILS_ROUTE, { userId: product?.merchant?.id }))
  );

  return (
    <Box display="flex" gap={1.5}>
      {product?.imageUrl
        ? <CardMedia src={product?.imageUrl} component="img" alt={product?.imageUrl} onClick={handleSeeDetails} />
        : (
          <EmptyBackground onClick={handleSeeDetails}>
            <Panorama size={30} />
          </EmptyBackground>
        )}
      <div>
        <Typography variant="subtitle2" onClick={handleSeeDetails} className="pointer" width="fit-content">
          {product?.name}
        </Typography>
        {product?.description && (
          <Description variant="subtitle2" color="textSecondary" fontWeight={400} mt={0.5}>
            {product?.description}
          </Description>
        )}
        <Typography variant="subtitle2" fontWeight={400} color="textSecondary" mt={0.5} mb={1}>
          {intl.formatMessage({ id: 'label.soldBy' })}:
          <Typography
            variant="subtitle2"
            color="primary"
            component="span"
            className="pointer"
            ml={0.5}
            onClick={handleSeeUser}
          >
            {product?.merchant?.name}
          </Typography>
        </Typography>
        <ProductPrice
          price={product?.price ? { mainPrice: product?.price } : null}
          msrp={product?.msrp || null}
          productId={product?.id}
          isActive={isMobile}
          variant="subtitle2"
        />
        <Box display="flex" alignItems="center" gap={1.5} ml={-1}>
          <IconButton onClick={() => handleChangeQuantity(ActionType.decrease)}>
            <Remove size={18} color={theme.palette.primary.main} />
          </IconButton>
          <Typography>
            {product?.quantity}
          </Typography>
          <IconButton onClick={() => handleChangeQuantity(ActionType.increase)}>
            <Add size={18} color={theme.palette.primary.main} />
          </IconButton>
        </Box>
        {errorMessage && (
          <Typography variant="subtitle2" fontWeight={400} color="error" mt={1}>
            {errorMessage}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default ProductDetails;
