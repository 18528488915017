import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ backgroundColor?: string }>(({ theme, backgroundColor }) => ({
  borderRadius: '8px',
  backgroundColor: backgroundColor || theme.palette.common.white,
  color: theme.palette.common.white,
  marginBottom: theme.spacing(2),

  '& .MuiCardMedia-root': {
    borderRadius: '8px',
    height: '350px',

    [theme.breakpoints.down('md')]: {
      height: '250px',
    },
  },

  '.skeletonImage': {
    height: '350px',
    marginBottom: theme.spacing(1),

    [theme.breakpoints.down('md')]: {
      height: '250px',
    },
  },

  '& .MuiSkeleton-root': {
    borderRadius: '8px',
  },
}));

export const InfoSection = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.35),
  position: 'absolute',
  height: '100%',
  width: '100%',
  bottom: 0,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  overflowY: 'auto',
  padding: theme.spacing(2),
}));

export const HeaderSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  top: 0,
  zIndex: 1,
  padding: theme.spacing(2),
}));

export const StatsContainer = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.85),
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  color: theme.palette.common.white,

  '& svg': {
    fill: theme.palette.common.white,
  }
}));
