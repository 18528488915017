import { isBefore } from 'date-fns';
import { User } from 'firebase/auth';

import { SENTS_FOLDER_BY_TYPE } from 'constants/bounty';
import { BountyState, BountyType } from 'constants/enums';
import { Bounty, BountyStats, MetaInfo } from 'models/bounty.interface';
import { BountyInfo } from 'models/bountyResponse.interface';
import { isSurveyType } from 'utils/survey';
import { DESCRIPTION_SEP } from 'utils/yaml';

export const isBountyExpired = (state: BountyState, expiresAt?: number) => (
  state === BountyState.Expired || (expiresAt && shouldCheckForExpiry(state) && isExpired(expiresAt))
);

export const isBountyClosed = (state: BountyState) => (
  [BountyState.Closed, BountyState.Expired, BountyState.Archived].includes(state)
);

export function isExpired(expiresAt: number) {
  return expiresAt && isBefore(expiresAt, new Date());
}

export const shouldCheckForExpiry = (state: BountyState) => state === BountyState.Active;

export const isOutboundPost = (metaInfo?: MetaInfo) => metaInfo && metaInfo.outbound;

export const adjustLikesStat = (stats: BountyStats, adjustment: number) => {
  const newStats = stats || { likesCount: 0 };
  const { likesCount = 0 } = newStats;

  newStats.likesCount = likesCount + adjustment;

  return newStats;
};

export const getCampaignDescriptionText = (description: { text: string }) => {
  const { text } = description;
  return text.split(DESCRIPTION_SEP)[0];
};

export const isPausedStory = (bounty: Bounty) => (
  isSurveyType(bounty.type) || [BountyType.Trivia, BountyType.TMOB_Challenge].includes(bounty.type)
);

export const asBountyInfo = (bounty: Bounty): BountyInfo => ({
  id: bounty.id,
  creator: bounty.creator,
  author: bounty.author || null,
  type: bounty.type,
  description: bounty.description?.text || null,
  shortId: bounty.shortId || null,
  title: bounty.title || null,
});

export const isSubBounty = (bounty: Bounty): boolean => !!(bounty.parentBounty && bounty.parentBounty.id !== null);

export const getOwnerId = (bounty: Partial<Bounty>) => bounty?.owner?.id || null;

export const getCompanyFolder = (type: BountyType) => SENTS_FOLDER_BY_TYPE[type] || null;

export const isMyBounty = (bounty: Bounty, user: User) => bounty.creator && bounty.creator.id === user.uid;

export const isSharedWithCompany = (bounty: Bounty, user: User) => {
  const companyFolder = getCompanyFolder(bounty.type);

  if (!companyFolder) {
    return false;
  }

  switch (bounty.type) {
    case BountyType.Order:
      return !isMyBounty(bounty, user);
    default:
      return true;
    }
};

export const sortBounties = (bounties: Bounty[]) => {
  const copy = bounties ? [...bounties] : [];
  return copy?.sort((a, b) => {
    const orderInParentSortKeyA = a.orderInParentSortKey?.split('--')?.[1]?.toLowerCase() || '';
    const orderInParentSortKeyB = b.orderInParentSortKey?.split('--')?.[1]?.toLowerCase() || '';
    if (orderInParentSortKeyA < orderInParentSortKeyB) {
      return -1;
    }
    if (orderInParentSortKeyA > orderInParentSortKeyB) {
      return 1;
    }

    return 0;
  });
};

export const sortTriviaSubBounties = (bounties: Bounty[]) => {
  const mcqs = bounties?.filter(({ type }: Bounty) => type !== BountyType.Thread);
  const successBounty = bounties?.find(({ type }: Bounty) => type === BountyType.Thread);
  const sortedBounties = sortBounties(mcqs);

  if (successBounty) {
    return [...sortedBounties, successBounty];
  }

  return sortedBounties;
};

export const allowReplies = (bountyType: BountyType) => (
  [
    BountyType.TMOB_Challenge,
    BountyType.TMOB_Coaching,
    BountyType.Thread,
  ].includes(bountyType)
);

export const getSpentTime = (stoppedAt: number, timer?: number) => {
  if (!timer) {
    return null;
  }

  const timeSpent = timer - stoppedAt;
  return timeSpent * 1000;
};

export const isPartOfSeries = (bounty?: Bounty) => {
  if (!bounty) {
    return false;
  }

  return !!bounty?.activities?.nextActivity || !!bounty?.activities?.previousActivity;
};
