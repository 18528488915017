
const config = {
  env: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyA8Mr6x0b3QNy15JkhSQEji6_v2miFqyE4',
    authDomain: 'campaigns-abd4a.firebaseapp.com',
    databaseURL: 'https://campaigns-abd4a-default-rtdb.firebaseio.com',
    projectId: 'campaigns-abd4a',
    storageBucket: 'campaigns-abd4a.appspot.com',
    messagingSenderId: '450530323659',
    appId: '1:450530323659:web:f563bb757f84a95f357f4e',
    measurementId: 'G-D8K0N3P9EY',
  },
  siteId: 'campaigns-view',
  stripeKey: 'pk_live_8n9cBjQ64DllvAPgH09bL0Dk',
  stripeTestKey: 'pk_test_Wadgf1aQfWi4h1btcl6Nd5Ox',
  API_BASE_URL: 'https://edge-server-zosrjn6zma-uc.a.run.app',
  SENTRY_CONFIG: {
    dsn: 'https://413ecba415d13f628ed6795cfbb9f504@o116454.ingest.sentry.io/4506437813534720',
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  },
  tosUrl: 'https://getcampaigns.com/tos',
  privacyUrl: 'https://getcampaigns.com/privacy',
  campaignIOS: 'https://apps.apple.com/us/app/campaigns-boost-engagement/id6466628652?_branch_match_id=1395798566947600137&utm_source=VIEW%20App&utm_campaign=Onboarding&utm_medium=marketing&_branch_referrer=H4sIAAAAAAAAA8soKSkottLXT07MLUjMTM8r1kssKNDLyczL1gcyikvyi1Lt64pS01KLijLz0uOTivLLi1OLbJ0zivJzUwH77ucKPQAAAA%3D%3D',
  campaignTestingUrl: 'https://campaigns-abd4a--testapp-89166h2b.web.app/',
  campaignUrl: 'https://console.getcampaigns.com/',
};

export default config;
