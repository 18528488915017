import { Box } from '@mui/material';
import { MediaPlayer, MediaPlayerClass } from 'dashjs';
import { FC, useEffect, useRef, useState } from 'react';

import { VideoControl } from 'containers/VideoPlayer/index.styled';

import { useAppDispatch } from 'hooks/useRedux';
import { setIsSeeking } from 'store/system/systemSlice';

import ArrowPause from 'assets/icons/ArrowPause';
import ArrowPlay from 'assets/icons/ArrowPlay';

const config = {
  file: {
    attributes: { controlsList: 'nodownload' }, // Prevent downloading
  },
};

interface VideoPlayerProps {
  url: string;
  onPlay?: () => void;
  onLoaded?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
  width?: string;
  height?: string;
  playsInline?: boolean;
  isPlaying?: boolean;
  muted?: boolean;
  loop?: boolean;
  isManifest?: boolean;
  controls: boolean;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  url,
  onPlay,
  onLoaded,
  onPause,
  onEnd,
  width = '100%',
  height = '100%',
  playsInline = false,
  isPlaying = false,
  isManifest = false,
  muted = false,
  loop = false,
  controls,
}) => {
  const dispatch = useAppDispatch();
  const isSeekingRef = useRef(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [player, setPlayer] = useState<MediaPlayerClass>();

  useEffect(() => {
    if (videoRef.current && url && !player) {
      // Dash.js supports only .mpd files
      if (isManifest) {
        const mediaPlayer = MediaPlayer().create();
        mediaPlayer.initialize(videoRef.current, url, false);
        setPlayer(mediaPlayer);
      }

      const videoElement = videoRef.current;
      Object.entries(config.file.attributes).forEach(([key, value]) => {
        videoElement.setAttribute(key, value as string);
      });
    }
  }, [videoRef.current, url, isManifest]);

  useEffect(() => {
    if (videoRef.current && (player || !isManifest)) {
      handlePlayVideo(videoRef.current);
    }
  }, [videoRef.current, isPlaying, player, isManifest]);

  const handlePlayVideo = async (ref: HTMLVideoElement) => {
    if (isPlaying) {
      try {
        await ref.play();
      } catch (error: any) {
        console.error('Error playing video:', error);
      }
    } else {
      ref.pause();
    }
  };

  const handleSeeking = () => {
    if (!isSeekingRef.current) {
      isSeekingRef.current = true;
      dispatch(setIsSeeking(true));
    }
  };

  const handleSeeked = () => {
    if (isSeekingRef.current) {
      isSeekingRef.current = false;
      dispatch(setIsSeeking(false));
    }
  };

  const handleVideoControl = (global?: boolean) => {
    if (global && controls) {
      return;
    }

    if (isPlaying && onPause) {
      onPause();
    } else if (!isPlaying && onPlay) {
      onPlay();
    }
  };

  return (
    <Box position="relative" onClick={() => handleVideoControl(true)}>
      <video
        loop={loop}
        ref={videoRef}
        controls={controls}
        width={width}
        height={height}
        playsInline={playsInline}
        onPlay={onPlay}
        onPause={onPause}
        onEnded={onEnd}
        muted={muted}
        onSeeking={handleSeeking}
        onSeeked={handleSeeked}
        onLoadedMetadata={onLoaded}
        src={isManifest ? undefined : url}
      />
      <VideoControl
        isPlaying={isPlaying}
        onClick={() => handleVideoControl()}
      >
        {isPlaying ? <ArrowPause size={40} /> : <ArrowPlay size={40} />}
      </VideoControl>
    </Box>
  );
};

export default VideoPlayer;
