import { Address } from 'models/address.interface';

export const getNewestAddress = (addresses: { [key: string]: Address }): Address|null => {
  if (!addresses || !Object.keys(addresses).length) {
    return null;
  }

  const addressKeys = Object.keys(addresses);
  const lastAddressKey = addressKeys[addressKeys.length - 1];

  return addresses[lastAddressKey];
};
