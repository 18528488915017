import { FC } from 'react';

import { CardMedia } from 'components/Attachment/index.styled';
import Pdf from 'components/Pdf';
import VideoPlayer from 'containers/VideoPlayer';

import DocFile from 'assets/images/docFilePlaceholder.svg';
import { AttachmentType } from 'constants/enums';

interface AttachmentProps {
  type?: AttachmentType;
  url?: string;
  height?: number|string;
  width?: number|string;
  className?: string;
  isManifest?: boolean;
}

const Attachment: FC<AttachmentProps> = ({
  type = AttachmentType.Image,
  url = '',
  height,
  width,
  isManifest = false,
  ...otherProps
}) => {
  switch (type) {
    case AttachmentType.Other:
    case AttachmentType.File:
      return (
        <CardMedia
          src={DocFile}
          component="img"
          alt={url}
        />
      );
    case AttachmentType.Pdf:
      return <Pdf {...otherProps} url={url} />;
    case AttachmentType.Audio:
    case AttachmentType.Video:
      return <VideoPlayer controls url={url} width="100%" height="100%" isManifest={isManifest} />;
    case AttachmentType.Image:
    default:
      return (
        <CardMedia
          src={url}
          component="img"
          alt={url}
          sx={{
            height: height || '100%',
            width: width || '100%',
          }}
          {...otherProps}
        />
      );
    }
};

export default Attachment;
