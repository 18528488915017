import { Box, SxProps } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';

import { IconWrapper } from './index.styled';

interface IconInfoWrapperProps {
  icon?: ReactElement | null;
  children?: ReactNode;
  onClick?: () => void;
  sx?: SxProps;
  isDark?: boolean;
  height?: string;
  width?: string;
}

const IconInfoWrapper: FC<IconInfoWrapperProps> = ({
  icon,
  onClick,
  children = null,
  sx = {},
  isDark = false,
  height = '30px',
  width = '30px',
}) => (
  <Box display="flex" alignItems="center" sx={{ mt: 1.5, ...sx }} gap={1.5} width="fit-content">
    {icon && (
      <IconWrapper isDark={isDark} onClick={onClick} sx={{ height, width }}>
        {icon}
      </IconWrapper>
    )}

    {children}
  </Box>
);

export default IconInfoWrapper;
