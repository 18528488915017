import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ width: string }>(({ width }) => ({
  '& .MuiPaper-root': {
    width,
  },
}));

export const CarouselContainer = styled('div')<{ sliding: boolean }>(({ sliding }) => ({
  display: 'flex',
  transition: sliding ? 'none' : 'transform 0.5s ease',
}));

export const Wrapper = styled('div')<{ imageSize: string }>(({ imageSize }) => ({
  width: imageSize,
  height: imageSize,
  overflow: 'hidden',
  borderRadius: '8px',
}));

export const CarouselSlot = styled('div')<{ order: number }>(({ order }) => ({
  flex: '1 0 100%',
  flexBasis: '100%',
  order,
}));

export const Item = styled('div')(() => ({
  textAlign: 'center',
  padding: '93px 166px',
  position: 'absolute',
}));

export const SlideButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  width: '100%',
  top: 0,
  bottom: 0,
  marginTop: 'auto',
  marginBottom: 'auto',
  height: '50px',
  padding: theme.spacing(0, 1),

  '& svg > path': {
    fill: theme.palette.common.white,
  },

  '& .MuiIconButton-root': {
    backgroundColor: '#00000040',

    '&:hover': {
      backgroundColor: '#00000070',
    },
  },
}));
