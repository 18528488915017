import {
  configureStore, ThunkAction, Action,
} from '@reduxjs/toolkit';

import campaignReducer from 'store/campaign/campaignSlice';
import cartReducer from 'store/cart/cartSlice';
import leaderboardReducer from 'store/leaderboard/leaderboardSlice';
import seriesReducer from 'store/series/seriesSlice';
import signinInvitationReducer from 'store/signinInvitation/signinInvitationSlice';
import systemReducer from 'store/system/systemSlice';
import userReducer from 'store/user/userSlice';

export const store = configureStore({
  reducer: {
    campaign: campaignReducer,
    leaderboard: leaderboardReducer,
    signinInvitation: signinInvitationReducer,
    system: systemReducer,
    series: seriesReducer,
    user: userReducer,
    cart: cartReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
