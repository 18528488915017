import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import DownloadAppOverlay from 'components/DownloadAppOverlay';
import UserAvatar from 'components/UserAvatar';
import UserBanner from 'components/UserBanner';
import BottomNavigation from 'containers/MerchantProfile/BottomNavigation';
import Campaigns from 'containers/MerchantProfile/Campaigns';
import Description from 'containers/MerchantProfile/Description';
import Follow from 'containers/MerchantProfile/Follow';
import FollowMerchantOverlay from 'containers/MerchantProfile/FollowMerchantOverlay';
import { TabContent, Page, Content } from 'containers/MerchantProfile/index.styled';
import Name from 'containers/MerchantProfile/Name';
import Products from 'containers/MerchantProfile/Products';
import SigninInvitation from 'containers/SigninInvitation';

import { getOpenUserProfile } from 'services/User';

import { UserProfile } from 'models/userProfile.interface';
import { handleApiErrors } from 'utils/error';
import { getUsernameInitials } from 'utils/user';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

const MerchantProfile = () => {
  const intl = useIntl();
  const { userId } = useParams();
  const [tab, setTab] = useState(0);
  const [user, setUser] = useState<UserProfile|null>(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(true);

  useEffect(() => {
    if (userId) {
      loadUserProfile(userId);
    }
  }, [userId]);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const loadUserProfile = async (id: string) => {
    try {
      setIsLoadingProfile(true);
      const { data } = await getOpenUserProfile(id);
      setUser(data);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoadingProfile(false);
    }
  };

  return (
    <>
      <Page>
        <Content id="profileContent" className="hidden-scroll">
          <UserBanner
            src={user?.basicProfile?.coverImageUrl}
          >
            <UserAvatar
              src={user?.basicProfile?.user?.avatar}
              userInitials={getUsernameInitials(user?.basicProfile?.user?.name)}
              height="90px"
              width="90px"
              opacity={1}
              sx={{ fontSize: '18px' }}
            />
          </UserBanner>

          <Box px={2.5} position="relative">
            <Name
              isLoading={isLoadingProfile}
              name={user?.basicProfile?.user?.name}
              reputation={user?.basicProfile?.user?.reputation}
            />

            <Description
              isLoading={isLoadingProfile}
              description={user?.basicProfile?.description}
            />

            {!!user?.basicProfile?.user?.id && (
              <Follow
                username={user?.basicProfile?.user?.name || ''}
                userId={user?.basicProfile?.user?.id}
              />
            )}
          </Box>

          <Tabs value={tab} onChange={handleTabChange} aria-label="tab" sx={{ mt: 3, ml: 2.5 }}>
            <Tab
              label={intl.formatMessage({ id: 'button.shop' })}
              {...a11yProps(1)}
            />
            <Tab
              label={intl.formatMessage({ id: 'button.campaigns' })}
              {...a11yProps(0)}
            />
          </Tabs>

          <TabContent>
            {tab === 0
              ? <Products />
              : <Campaigns authorName={user?.basicProfile?.user?.name || ''} />}
          </TabContent>
        </Content>
        {!!user?.basicProfile?.user?.id && (
          <FollowMerchantOverlay user={user?.basicProfile?.user} />
        )}
        <DownloadAppOverlay />
      </Page>
      <BottomNavigation />
      <SigninInvitation />
    </>
  );
};

export default MerchantProfile;
