import { Box, Button, Fade, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStoryDimensions from 'hooks/useStoryDimensions';
import { isSignInOverlayOpenSelector } from 'store/signinInvitation/selectors';
import { openModalSignIn, setSignInOverlay } from 'store/signinInvitation/signinInvitationSlice';

import { StoryOverlay } from 'assets/theme/styled/StoryOverlay.styled';

const SignInOverlay = () => {
  const dispatch = useAppDispatch();
  const isSignInOverlayOpen = useAppSelector(isSignInOverlayOpenSelector) || false;
  const { height } = useStoryDimensions();
  const intl = useIntl();

  const handleClose = () => dispatch(setSignInOverlay(false));

  const handleCreate = () => {
    dispatch(setSignInOverlay(false));
    dispatch(openModalSignIn());
  };

  return (
    <Fade timeout={500} in={isSignInOverlayOpen} unmountOnExit mountOnEnter>
      <StoryOverlay height={height}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          <Typography textAlign="center" color="common.white" variant="title1" mb={15}>
            {intl.formatMessage({ id: 'label.keepYourTokensSafe' })}
          </Typography>
          <Typography textAlign="center" color="common.white" variant="subtitle1" maxWidth="325px" mb={5}>
            {intl.formatMessage({ id: 'label.signUpToKeepTrackOf' })}
          </Typography>
          <Button variant="contained" fullWidth onClick={handleCreate} className="big" sx={{ mb: 2.5 }}>
            {intl.formatMessage({ id: 'button.createYourAccountNow' })}
          </Button>
          <Button variant="outlinedLightPrimary" fullWidth onClick={handleClose} className="big">
            {intl.formatMessage({ id: 'button.close' })}
          </Button>
        </Box>
      </StoryOverlay>
    </Fade>
  );
};

export default SignInOverlay;
