import { alpha, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconWrapper = styled(
  Avatar,
  { shouldForwardProp: (props) => props !== 'isDark' },
)<{ isDark: boolean }>(({ theme, isDark }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fill: isDark ? alpha(theme.palette.common.white, 0.5) : theme.palette.common.black,
  backgroundColor: isDark ? alpha(theme.palette.common.black, 0.5) : theme.palette.common.white,
}));
