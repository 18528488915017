import { Box } from '@mui/material';
import MuiBadge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  borderRadius: '32px',
  backgroundColor: theme.palette.background.main,
  padding: theme.spacing(1, 1.5),
}));

export const Badge = styled(MuiBadge)(() => ({
  '& .MuiBadge-badge': {
    right: -6,
    top: -8,
    whiteSpace: 'nowrap',
  },
}));
