import yaml from 'js-yaml';

export const DESCRIPTION_SEP = '---';

export const parseEncryptedDescription = <T>(description: { text: string }): T => {
  const { text } = description;
  const info = text.split(DESCRIPTION_SEP)[1];
  const decodedInfo = info ? yaml.load(info) : {};
  return decodedInfo as T;
};
