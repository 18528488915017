import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { FbUserContext } from 'context/FbUserContext';

import { getUserProfile } from 'services/User';

import flavourConfig from 'configuration/env';

interface StripeProviderProps {
  children: ReactElement;
}

const StripeProvider:FC<StripeProviderProps> = ({ children }) => {
  const intl = useIntl();
  const { fbUser } = useContext(FbUserContext);
  const [livePaymentMode, setLivePaymentMode] = useState<boolean>(false);
  const { stripeKey, stripeTestKey } = flavourConfig;
  const stripePromise = loadStripe(livePaymentMode ? stripeKey : stripeTestKey);

  useEffect(() => {
    if (fbUser?.uid) {
      fetchUserProfile();
    }
  }, [fbUser?.uid]);

  const fetchUserProfile = async () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    try {
      const response = await getUserProfile(fbUser?.uid);
      setLivePaymentMode(response?.data?.basicProfile?.livePaymentMode || false);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default StripeProvider;
