import { User } from 'firebase/auth';
import { ref, child } from 'firebase/database';

import { db } from 'configuration/firebase';
import { OutboundResponseRefType } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { BountyResponse } from 'models/bountyResponse.interface';
import { isSubBounty } from 'utils/bounty';

export const getUserDataRef = (userId: string) => ref(db, `userData/${userId}`);
export const getSystemSettingsRef = () => ref(db, 'system/system/settings');
export const getMyCompanySettingsRef = (companyId: string) => ref(db, `companies/${companyId}/settings`);
export const getOrgTypeSettingsRef = (orgType: string) => ref(db, `/system/orgTypes/${orgType}/settings`);

export const getUserSentsRef = (userId: string) => ref(db, `userSents/${userId}`);

export const getUserStreamsRef = () => ref(db, 'user/stream/personal');

export const getUserResponsesRef = (ownerId: string) => ref(db, `bountyResponses/${ownerId}`);

export const getUserBountyCommentsRef = (bountyId: string) => ref(db, `userComments/bounties/${bountyId}`);

export const getUserResponseCommentsRef = (responseId: string) => ref(db, `userComments/responses/${responseId}`);

export const getUserResponseCommentsCountRef = (ownerId: string, bountyId: string, responseId: string) => (
  ref(db, `bountyResponses/${ownerId}/${bountyId}/${responseId}/response/stats/commentCount`)
);

export const getMySubCardsRef = (userId: string) => ref(db, `userSubCards/${userId}`);

export const getInboundRef = (bounty: Bounty, user: User) => {
  if (isSubBounty(bounty) && bounty.parentBounty) {
    return child(getMySubCardsRef(user.uid), `${bounty.parentBounty.id}`);
  }

  return getUserStreamsRef();
};

export const getOutboundResponseRefs = (response: BountyResponse, user: User) => {
  const ref2 = child(getUserSentsRef(user.uid), `${response.id}/response`);

  return {
    [OutboundResponseRefType.sentItems]: ref2,
  };
};

export const userSocialActionStateRef = (loggedUserId: string, userId: string, state: string) => (
  ref(db, `userSocials/${loggedUserId}/${userId}/${state}`)
);

export const bountyPersonalLayerRef = (userId: string, bountyId: string) => (
  ref(db, `personal/users/${userId}/bounties/${bountyId}/personal`)
);

export const responsePersonalLayerRef = (userId: string, responseId: string) => (
  ref(db, `personal/users/${userId}/responses/${responseId}/personal`)
);

export const commonResponseStatsRef = (bountyId: string, responseId: string) => (
  ref(db, `common/responses/${bountyId}/${responseId}/stats`)
);

export const clientInfoRef = (clientId: string) => ref(db, `clients/${clientId}`);

export const getShoppingCartRef = (shoppingCartId: string, user: User) => (
  ref(db, `shoppingCarts/${user.uid}/${shoppingCartId}`)
);

export const getUserAddressesRef = (userId: string) => ref(db, `userData/${userId}/addresses`);

export const getUserPaymentsRef = (userId: string) => ref(db, `userData/${userId}/payments`);
