import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import UserAvatar from 'components/UserAvatar';

import { USER_DETAILS_ROUTE } from 'constants/clientRoutes';
import { User } from 'models/user.interface';
import { formatRoute } from 'utils/formatters';
import { getUsernameInitials } from 'utils/user';

interface SoldByProps {
  user?: User;
}

const SoldBy: FC<SoldByProps> = ({ user }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleSeeUser = () => {
    navigate(formatRoute(USER_DETAILS_ROUTE, { userId: user?.id }));
  };
  
  return (
    <Box display="flex" alignItems="center" gap={0.5}>
      <Typography>
        {intl.formatMessage({ id: 'label.soldBy' })}
      </Typography>
      <Box display="flex" alignItems="center" gap={0.5} className="pointer" onClick={handleSeeUser}>
        <UserAvatar
          src={user?.avatar}
          userInitials={getUsernameInitials(user?.name)}
          height="34px"
          width="34px"
          opacity={1}
          sx={{ fontSize: '18px' }}
        />
        <Typography>
          {user?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default SoldBy;
