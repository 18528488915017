import { CardInfo } from 'models/cardInfo.interface';

export const getNewestCreditCard = (creditCards: Record<string, CardInfo>) => {
  if (!creditCards || !Object.keys(creditCards).length) {
    return null;
  }

  const creditCardsKeys = Object.keys(creditCards);
  const lastCreditCardKey = creditCardsKeys[creditCardsKeys.length - 1];

  return creditCards[lastCreditCardKey];
};
