import { styled } from '@mui/material';

export const Root = styled('div')<{ hasError: boolean }>(({ theme, hasError }) => {
  const borderColor = hasError ? theme.palette.error.main : theme.palette.general.fieldBorder;
  const focusBorderColor = hasError ? theme.palette.error.main : theme.palette.primary.main;
  const errorCss = hasError ? {
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
  } : {};

  return {
    width: '100%',
    '& .react-tel-input .form-control': {
      borderRadius: '8px',
      height: '38.56px',
      width: '100%',
      fontSize: '1rem',
      fontFamily: 'Roboto, sans-serif',
      color: theme.palette.text.primary,
      borderColor,
      '&:hover': {
        borderColor: `${hasError ? theme.palette.error.main : theme.palette.general.fieldBorder} !important`,
      },
      '&:focus': {
        border: `2px solid ${focusBorderColor}`,
      },
    },
    '& .flag-dropdown': {
      borderRadius: '8px 0 0 8px !important',

      '& .selected-flag': {
        borderRadius: '8px 0 0 8px !important',
      },
    },
    ...errorCss,
  };
});
