/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const Panorama = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24" width={size} fill={color}>
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M21 4H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H3V6h18v12zm-6.5-7L11 15.51 8.5 12.5 5 17h14z"/>
  </svg>
);

export default Panorama;
