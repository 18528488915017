import { AxiosResponse } from 'axios';
import { User } from 'firebase/auth';
import { child, push, remove, set, setWithPriority, update } from 'firebase/database';

import { runPostApiRequest } from 'services/Api';
import { getShoppingCartRef } from 'services/FirebaseRefs';

import { ORDER_SUMMARY_ROUTE, PLACE_ORDER_ROUTE } from 'constants/apiRoutes';
import { Address } from 'models/address.interface';
import { Bounty } from 'models/bounty.interface';
import { CardInfo } from 'models/cardInfo.interface';
import { Cart } from 'models/cart.interface';
import { OrderSummaryResult } from 'models/order.interface';
import { ProductInfo } from 'models/product.interface';
import { valueOfProductInfo } from 'utils/cart';
import { formatRoute } from 'utils/formatters';
import { getPriorityForProduct } from 'utils/responseKey';

interface UpdateQuantity {
  newQuantity: number;
  productInfo: ProductInfo;
  user: User;
}

export const getMyShoppingCartId = (user: User) => `cart_${user?.uid}`;

export const getShoppingCartProductsRef = (shoppingCartId: string, user: User) => (
  child(getShoppingCartRef(shoppingCartId, user), 'products')
);

const addToShoppingCart = (shoppingCartId: string, productInfo: ProductInfo, user: User) => {
  const ref = push(getShoppingCartProductsRef(shoppingCartId, user));

  if (ref.key) {
    const productInfoClone = { ...productInfo, id: ref.key };
    setWithPriority(ref, productInfoClone, getPriorityForProduct(productInfoClone));
  }
};

export const addToMyShoppingCart = (bounty: Bounty, user: User) => {
  addToShoppingCart(getMyShoppingCartId(user), valueOfProductInfo(bounty), user);
};

export const saveRedemptionAmountToCart = (redemptionAmount: string, user: User) => {
  const redemptionAmountRef = child(getShoppingCartRef(getMyShoppingCartId(user), user), 'redemptionAmount');
  set(redemptionAmountRef, redemptionAmount);
};

export const saveCouponToCart = (coupon: string, user: User) => {
  const couponRef = child(getShoppingCartRef(getMyShoppingCartId(user), user), 'coupon');
  set(couponRef, coupon);
};

export const saveAddressToCart = (address: Address, user: User) => {
  const addressRef = child(getShoppingCartRef(getMyShoppingCartId(user), user), 'deliveryAddress');
  set(addressRef, address);
};

export const savePaymentToCart = (payment: CardInfo, user: User) => {
  const paymentRef = child(getShoppingCartRef(getMyShoppingCartId(user), user), 'paymentInfo');
  set(paymentRef, payment);
};

export const removeFromShoppingCart = (productInfo: ProductInfo, shoppingCartId: string, user: User) => {
  if (!productInfo) {
    return;
  }

  const cartProductRef = child(getShoppingCartProductsRef(shoppingCartId, user), productInfo.id);
  remove(cartProductRef);
};

export const updateProductQuantity = (params: UpdateQuantity) => {
  const { user, productInfo, newQuantity } = params;
  const shoppingCartId = getMyShoppingCartId(user);

  if (!productInfo) {
    return;
  }

  if (newQuantity === 0) {
    removeFromShoppingCart(productInfo, shoppingCartId, user);
    return;
  }

  const cartProductRef = child(getShoppingCartProductsRef(shoppingCartId, user), productInfo.id);
  update(cartProductRef, {
    updatedAt: new Date().getTime(),
    quantity: newQuantity,
  });
};

export const calculateOrderSummary = (cart: Cart, user: User): Promise<AxiosResponse<OrderSummaryResult>> => {
  const cartId = getMyShoppingCartId(user);
  const route = formatRoute(ORDER_SUMMARY_ROUTE, { cartId });

  return runPostApiRequest(route, { ...cart, id: cartId });
};

export const placeOrder = (cart: Cart, user: User): Promise<AxiosResponse<{ orderId: string }>> => {
  const cartId = getMyShoppingCartId(user);
  const route = formatRoute(PLACE_ORDER_ROUTE, { cartId });

  return runPostApiRequest(route, { ...cart, id: cartId });
};
