import { alpha, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const VideoControl = styled(
  IconButton,
  { shouldForwardProp: (props) => props !== 'isPlaying' },
)<{ isPlaying: boolean }>(({ theme, isPlaying }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: alpha(theme.palette.common.white, 0.3),
  visibility: isPlaying ? 'hidden' : 'visible',
  height: '80px',
  width: '80px',

  '& svg': {
    fill: theme.palette.common.white,
  },

  ':hover': {
    visibility: 'visible',
    backgroundColor: alpha(theme.palette.common.white, 0.3),
  }
}));
