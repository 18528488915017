import { isEmpty } from 'lodash';

import { PointCurrency, RewardType } from 'constants/enums';
import { Reward, Price, Point, Money } from 'models/money.interface';
import { Product, ProductSpecificInfo } from 'models/product.interface';
import { isZeroMoney } from 'utils/money';
import { getPointsAmount, isZeroPoints } from 'utils/point';
import { formatReward } from 'utils/reward';

function isDiscount(initial: Reward|undefined, current: Reward|undefined): boolean {
  if (initial && current) {
    return !(JSON.stringify(initial) === JSON.stringify(current));
  }

  return false;
}

export function hasDiscount(initial?: Price|null, current?: Price|null): boolean {
  if (initial && current) {
    return isDiscount(initial.mainPrice, current.mainPrice)
      || isDiscount(initial.altPrice1, current.altPrice1)
      || isDiscount(initial.altPrice2, current.altPrice2);
  }

  return false;
}

function hasNoPrice(reward: Reward|undefined): boolean {
  if (!reward) {
    return true;
  }

  return (!reward.money || isZeroMoney(reward.money)) && !getPointsAmount(reward, PointCurrency.Point);
}

export function isFree(price: Price|null): boolean {
  if (!price) {
    return true;
  }

  return hasNoPrice(price.mainPrice) && hasNoPrice(price.altPrice1) && hasNoPrice(price.altPrice2);
}

function hasVariants(variants: { [key: string]: ProductSpecificInfo }): boolean {
  return Boolean(variants && Object.keys(variants).length);
}

function getVariant(variants: { [key: string]: ProductSpecificInfo }, sku: string): ProductSpecificInfo|null {
  if (!sku || !hasVariants(variants)) {
    return null;
  }

  return variants[sku];
}

export function getTopLevelVariant(product?: Product): ProductSpecificInfo|null {
  if (!product) {
    return null;
  }

  const { merchantSku, variants, imageUrl } = product;
  const productSpecificInfo = getVariant(variants, merchantSku);

  if (productSpecificInfo && !productSpecificInfo?.sku) {
    productSpecificInfo.sku = merchantSku;
  }

  if (productSpecificInfo && !productSpecificInfo?.imageUrl) {
    productSpecificInfo.imageUrl = imageUrl;
  }

  return productSpecificInfo;
}

export function getPrices(product: Product): { msrp: Price|null; price: Price|null } {
  if (!product) {
    return {
      msrp: null,
      price: null,
    };
  }

  const productInfo = getTopLevelVariant(product);
  let price = productInfo?.price || null;

  return {
    msrp: productInfo?.msrp || null,
    price,
  };
}

export const getCurrencyType = (price: Price) => {
  if (!isEmpty(price?.mainPrice?.money) && !isEmpty(price?.mainPrice?.points)) {
    return RewardType.Both;
  }

  if (!isEmpty(price?.mainPrice?.money) && !isEmpty(price?.altPrice1?.points)) {
    return RewardType.Either;
  }

  return !isEmpty(price?.mainPrice?.points) ? RewardType.Points : RewardType.Money;
};

export function formatPrice(price?: Price|null) {
  if (!price) {
    return '';
  }

  const rewardType = getCurrencyType(price);
  const separator = rewardType === RewardType.Both ? ' + ' : ' / ';
  const parts = [];

  if (price.mainPrice && Object.keys(price.mainPrice).length) {
    const reward = formatReward(price.mainPrice, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice1 && Object.keys(price.altPrice1).length) {
    const reward = formatReward(price.altPrice1, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  if (price.altPrice2 && Object.keys(price.altPrice2).length) {
    const reward = formatReward(price.altPrice2, '0');

    if (reward) {
      parts.push(reward);
    }
  }

  return parts.join(separator);
}

// Discounts
const moneyDiscount = (paid?: Money | null, price?: Money | null): number => {
  if (!paid || !price || isZeroMoney(price)) {
    return 0;
  } else {
    return 1.0 - +paid.amount / +price.amount;
  }
};

const pointDiscount = (paid: Point | null, price: Point | null): number => {
  if (!paid || !price || isZeroPoints(price)) {
    return 0;
  }

  return 1.0 - (paid.amount ?? 0) / (price.amount ?? 1);
};

export const largestMoneyDiscount = (initial?: Price|null, current?: Price|null) => {
  const p0 = Math.max(
    moneyDiscount(current?.mainPrice?.money, initial?.mainPrice?.money),
    moneyDiscount(current?.mainPrice?.money, initial?.altPrice1?.money),
    moneyDiscount(current?.mainPrice?.money, initial?.altPrice2?.money),
  );

  const p1 = Math.max(
    moneyDiscount(current?.altPrice1?.money, initial?.mainPrice?.money),
    moneyDiscount(current?.altPrice1?.money, initial?.altPrice1?.money),
    moneyDiscount(current?.altPrice1?.money, initial?.altPrice2?.money),
  );

  const p2 = Math.max(
    moneyDiscount(current?.altPrice2?.money, initial?.mainPrice?.money),
    moneyDiscount(current?.altPrice2?.money, initial?.altPrice1?.money),
    moneyDiscount(current?.altPrice2?.money, initial?.altPrice2?.money),
  );

  return Math.max(p0, p1, p2);
};

const largestOnePointDiscount = (paid?: Record<string, Point> | null, price?: Record<string, Point> | null): number => {
  if (!paid || !price || !Object.keys(paid).length || !Object.keys(price).length) {
    return 0;
  }

  let maxDiscount = 0;

  Object.keys(paid).forEach((key) => {
    const currentDiscount = pointDiscount(paid[key], price[key] || null);
    maxDiscount = Math.max(maxDiscount, currentDiscount);
  });

  return maxDiscount;
};

export const largestPointsDiscount = (initial?: Price|null, current?: Price|null) => {
  const p0 = Math.max(
    largestOnePointDiscount(current?.mainPrice?.points, initial?.mainPrice?.points),
    largestOnePointDiscount(current?.mainPrice?.points, initial?.altPrice1?.points),
    largestOnePointDiscount(current?.mainPrice?.points, initial?.altPrice2?.points),
  );

  const p1 = Math.max(
    largestOnePointDiscount(current?.altPrice1?.points, initial?.mainPrice?.points),
    largestOnePointDiscount(current?.altPrice1?.points, initial?.altPrice1?.points),
    largestOnePointDiscount(current?.altPrice1?.points, initial?.altPrice2?.points),
  );

  const p2 = Math.max(
    largestOnePointDiscount(current?.altPrice2?.points, initial?.mainPrice?.points),
    largestOnePointDiscount(current?.altPrice2?.points, initial?.altPrice1?.points),
    largestOnePointDiscount(current?.altPrice2?.points, initial?.altPrice2?.points),
  );

  return Math.max(p0, p1, p2);
};

export const discountPercent = (initial?: Price|null, current?: Price|null) => {
  const hasProductDiscount = hasDiscount(initial, current);

  if (!hasProductDiscount) {
    return null;
  }

  const moneyDiscount = largestMoneyDiscount(initial, current);
  const pointsDiscount = largestPointsDiscount(initial, current);

  const maxDiscount = Math.max(moneyDiscount, pointsDiscount);

  return (maxDiscount * 100).toFixed(0);
};
