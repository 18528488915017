import { Box, Typography, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Paper } from 'containers/Checkout/SectionWrapper/index.styled';

import FilledHelp from 'assets/icons/FilledHelp';

interface SectionWrapperProps {
  title: string;
  children: ReactNode | ReactNode[];
  onSeeInfo?: () => void;
  hasInfoIcon?: boolean;
}

const SectionWrapper: FC<SectionWrapperProps> = ({
  title,
  children,
  onSeeInfo,
  hasInfoIcon = false,
}) => {
  const theme = useTheme();

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
        <Typography variant="h1" color="textPrimary" mb={0.5}>
          {title}
        </Typography>
        {hasInfoIcon && (
          <div onClick={onSeeInfo} className="pointer">
            <FilledHelp color={theme.palette.primary.main} />
          </div>
        )}
      </Box>
      <Paper elevation={0}>
        {children}
      </Paper>
    </div>
  );
};

export default SectionWrapper;
