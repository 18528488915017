import { alpha, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import CustomNumberField from 'fields/NumberField';

export const ValueWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  borderRadius: '8px',
  padding: theme.spacing(1.5),
}));

export const NumberField = styled(CustomNumberField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
  },
}));
