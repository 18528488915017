import { Money } from 'models/money.interface';

export const isZeroMoney = (money: Money) => !money.amount || money.amount === '0' || money.amount === '0.0';

export const getAmountAsBigDecimal = (amount: string, enforceDecimal?: boolean) => {
  if (!amount || amount === '0' || amount === '0.0') {
    return 0;
  }

  try {
    const floatAmount = Number.parseFloat(amount);

    if (enforceDecimal) {
      return floatAmount.toFixed(2);
    }

    return (
      Number.isInteger(floatAmount)
        ? floatAmount.toString()
        : floatAmount.toFixed(2)
    );
  } catch {
    return 0;
  }
};

export const addMoney = (existingMoney?: Money | null, moneyToAdd?: Money | null): Money|null => {
  if (!existingMoney) {
    return moneyToAdd || null;
  }

  if (!moneyToAdd || !moneyToAdd.amount) {
    return existingMoney;
  }

  if (existingMoney.currency !== moneyToAdd.currency) {
    //  TODO: needs to be implemented
    return null;
    // amt = amt.convertTo(getCurrencyAsEnum());
    // if (amt == null) {
    //   return null;
    // }
  }

  if (!existingMoney.amount) {
    return moneyToAdd;
  }

  return {
    amount: (+existingMoney.amount + +moneyToAdd.amount).toString(),
    currency: existingMoney.currency,
  };
};

export const multiplyMoney = ({ amount, currency }: Money, quantity: number): Money => {
  const newAmount = +amount * quantity;

  return {
    amount: newAmount?.toString(),
    currency,
  };
};
