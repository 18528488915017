import { Box, Button, Fade, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import useStoryDimensions from 'hooks/useStoryDimensions';
import { showDownloadOverlaySelector } from 'store/system/selectors';
import { setShowDownloadOverlay } from 'store/system/systemSlice';

import { StoryOverlay } from 'assets/theme/styled/StoryOverlay.styled';
import flavourConfig from 'configuration/env';

const DownloadAppOverlay = () => {
  const dispatch = useAppDispatch();
  const isSignInOverlayOpen = useAppSelector(showDownloadOverlaySelector) || false;
  const { height } = useStoryDimensions();
  const intl = useIntl();
  
  const handleClose = () => dispatch(setShowDownloadOverlay(false));

  const handleIOSLink = async () => {
    window.open(flavourConfig.campaignIOS, '_blank');
  };

  return (
    <Fade timeout={500} in={isSignInOverlayOpen} unmountOnExit mountOnEnter>
      <StoryOverlay height={height}>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
          <Typography textAlign="center" color="common.white" variant="title1" mb={15} maxWidth="400px">
            {intl.formatMessage({ id: 'label.getAppEasyAccessWallet' })}
          </Typography>
          <Button variant="contained" fullWidth onClick={handleIOSLink} className="big" sx={{ mb: 1.5 }}>
            {intl.formatMessage({ id: 'button.downloadForIphone' })}
          </Button>
          <Typography textAlign="center" color="common.white" sx={{ opacity: 0.6 }} variant="subtitle1" mb={4.5}>
            {intl.formatMessage({ id: 'label.androidVersionComingSoon' })}
          </Typography>
          <Button variant="outlinedLightPrimary" fullWidth onClick={handleClose} className="big">
            {intl.formatMessage({ id: 'button.close' })}
          </Button>
        </Box>
      </StoryOverlay>
    </Fade>
  );
};
export default DownloadAppOverlay;
