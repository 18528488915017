import { alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

import { mainContentOffset, mainContentOffsetMobile } from 'assets/theme/customSpacing';

export const Content = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.general.darkGrey4,
  height: `calc(100% - ${mainContentOffset}px - 100px)`,
  borderTopRightRadius: '10px',
  borderTopLeftRadius: '10px',

  '& .stepper': {
    width: '100%',
    backgroundColor: theme.palette.general.darkGrey4,
  },

  '& .MuiCardMedia-root': {
    maxHeight: '430px',
    borderRadius: 0,

    [theme.breakpoints.down('sm')]: {
      maxHeight: '300px',
    },
  },

  '& .carouselWrapper': {
    maxHeight: '430px',
    borderRadius: 0,

    [theme.breakpoints.down('sm')]: {
      maxHeight: '300px',
    },
  },

  [theme.breakpoints.down('md')]: {
    height: `calc(100% - ${mainContentOffsetMobile}px - 100px)`,
  },
}));

export const EmptyBackground = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.2),
  height: '430px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  [theme.breakpoints.down('sm')]: {
    height: '300px',
  },
}));
