import { format } from 'date-fns';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { DateFormats } from 'constants/enums';
import { NOT_APPLICABLE } from 'constants/general';
import { Address } from 'models/address.interface';

export const formatDate = (date: number, dateFormat = DateFormats.defaultDate) => format(new Date(date), dateFormat);

export const formatRoute = (route: string, params: Record<string, any>): string => {
  let formattedRoute = route;

  Object.keys(params).forEach((key) => {
    formattedRoute = formattedRoute.replace(`:${key}`, params[key]);
  });

  return formattedRoute;
};

export const formatPercentage = (percentage: number) => {
  const floatPercentage = Number.parseFloat(percentage.toString()) * 100;
  return Math.floor(floatPercentage);
};

export const formatCartTotalQuantity = (total: number) => {
  if (total >= 100) {
    return '99+';
  }

  return total;
};

export const getFormattedNumber = ({
  value,
  enforceDecimal = false
}: { value: number; enforceDecimal?: boolean })=> (
  (+value).toLocaleString(undefined, { minimumFractionDigits: enforceDecimal ? 2 : 0 })
);

export const extractNumberFromNumericFormat = (value?: string | null) => {
  if (!value) {
    return 0;
  }

  const amount = value?.replaceAll(',', '');
  return amount ? +amount : 0;
};

export const formatAddressParts = (address: Address): string => {
  const parts = [];

  if (address?.city) {
    parts.push(address.city);
  }

  if (address?.state) {
    parts.push(address.state);
  }

  if (address?.countryCode) {
    parts.push(address.countryCode);
  }

  if (address?.postalCode) {
    parts.push(address.postalCode);
  }

  if (address.phoneNumber) {
    parts.push(formatPhoneNumber(address.phoneNumber));
  }

  return parts.join(', ');
};

export const formatAddress = (address: Address): string => {
  const parts = [];
  const addressPartsAsString = formatAddressParts(address);

  if (address.line1) {
    parts.push(address.line1);
  }

  if (address.line2) {
    parts.push(address.line2);
  }

  if (addressPartsAsString) {
    parts.push(addressPartsAsString);
  }

  return parts.join(', ');
};

export const formatPhoneNumber = (number?: string | null, emptyState: string = NOT_APPLICABLE) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!number) {
    return emptyState;
  }

  try {
    const phoneNumber = phoneUtil.parse(`+${number}`, '');
    const nationalFormat = phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
    const countryCode = `+${phoneNumber.getCountryCode()}`;

    return `${countryCode} ${nationalFormat}`;
  } catch {
    return emptyState;
  }
};
