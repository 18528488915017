export const sortByTimestamp = <T>(data: T[], sortBy: keyof T) => (
  data.sort((a, b) => {
    if (!a[sortBy] || !b[sortBy]) {
      return 1;
    }

    if (a[sortBy] < b[sortBy]) {
      return -1;
    }

    if (a[sortBy] > b[sortBy]) {
      return 1;
    }

    return 0;
  })
);

export const textTruncate = (str: string, length: number = 100, ending: string = '...'): string => {
  if (str && str.length > length) {
    return str.substring(0, length) + ending;
  }
  return str;
};

export const isString = (value: string|number) => (
  typeof value === 'string'
);

export const playSound = (sound: any) => {
  const audio = new Audio(sound);
  audio
    .play()
    .catch((e) => console.error('cannot play sound', e));

  if ('vibrate' in navigator) {
    navigator.vibrate(50);
  }
};
