import { RootState } from '~/store';

import { ApiStatus } from 'constants/enums';
import { Cart } from 'models/cart.interface';

export const cartSelector = (state: RootState): Cart => state.cart.data;
export const totalProductsQuantitySelector = (state: RootState): number => state.cart.totalProductsQuantity;
export const isLoadingSelector = (state: RootState): boolean => state.cart?.status === ApiStatus.loading;
export const hasCouponAppliedSuccessfullySelector = (state: RootState): boolean => (
  state.cart?.hasCouponAppliedSuccessfully
);
