import { Comment } from 'models/comment.interface';

export const getProductRating = (comments: Comment[]) => {
  let total = 0;

  if (!comments?.length) {
    return 0;
  }

  comments.forEach(({ reward }) => {
    if (reward && reward.points) {
      Object.values(reward.points).forEach((point) => {
        total += point.amount || 0;
      });
    }
  });

  return total / comments?.length;
};
