import { Box, Button, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { ValueWrapper, NumberField } from 'containers/Checkout/RedeemTokens/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { useDebounce } from 'hooks/useDebounce';
import { useAppSelector } from 'hooks/useRedux';
import { checkConversion } from 'services/Currency';
import { saveRedemptionAmountToCart } from 'services/ShoppingCart';
import { cartSelector } from 'store/cart/selectors';

import { Currency, PointCurrency } from 'constants/enums';
import { Money } from 'models/money.interface';
import { handleApiErrors } from 'utils/error';
import { extractNumberFromNumericFormat, getFormattedNumber } from 'utils/formatters';

interface RedeemTokensProps {
  tokenBalance: number;
  showWarningMessage?: boolean;
  remainingCartTotal?: Money | null;
  isLoadingTokens?: boolean;
}

const RedeemTokens: FC<RedeemTokensProps> = ({
  tokenBalance,
  remainingCartTotal,
  isLoadingTokens = false,
  showWarningMessage = false,
}) => {
  const intl = useIntl();
  const { fbUser } = useContext(FbUserContext);
  const { redemptionAmount } = useAppSelector(cartSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tokens, setTokens] = useState('');
  const [error, setError] = useState<string>('');
  const [tokensValue, setTokensValue] = useState('0');

  const debouncedTokens = useDebounce([tokens], 500);
  const formattedValue = getFormattedNumber({ value: +tokensValue, enforceDecimal: true });
  const isDisabled = tokenBalance <= 0;

  useEffect(() => {
    const newTokens = redemptionAmount?.split(' ')?.[0] || '';

    if (!tokens && newTokens !== tokens) {
      setTokens(newTokens);
    }
  }, [redemptionAmount]);

  useEffect(() => {
    fetchTokensExchangeValue();
  }, [debouncedTokens]);

  const fetchTokensExchangeValue = async () => {
    setIsSubmitting(true);
    const redeemTokensValue = tokens ? extractNumberFromNumericFormat(tokens).toString() : '0';

    if (+redeemTokensValue > tokenBalance) {
      setError('error.redeemTokensExceedTokenBalance');
      setIsSubmitting(false);
      return;
    }

    try {
      const { data } = await checkConversion(PointCurrency.Systkn, redeemTokensValue || '0', Currency.USD);
      setTokensValue(data?.rates?.[Currency.USD]?.exchangedReward?.money?.amount || '0');
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const saveRedeemTokens = async () => {
    if (!fbUser) {
      return;
    }

    if (showWarningMessage) {
      toast.warning(intl.formatMessage({ id: 'warning.tokensRedeemableWhenUSD' }));
      return;
    }

    if (isNil(tokens) || tokens === '' || +tokensValue === 0) {
      saveRedemptionAmountToCart('', fbUser);
      return;
    }

    if (+tokensValue > 0 && +tokensValue < 0.5) {
      toast.warning(intl.formatMessage({ id: 'warning.redeemMinimumValue' }));
      return;
    }

    try {
      const { data } = await checkConversion(
        PointCurrency.Systkn,
        redemptionAmount?.split(' ')?.[0] || '0',
        Currency.USD,
      );
      const currentRedeemValue = data?.rates?.[Currency.USD]?.exchangedReward?.money?.amount || '0';

      if (!remainingCartTotal || isNil(remainingCartTotal.amount) || remainingCartTotal.currency !== Currency.USD) {
        toast.error(intl.formatMessage({ id: 'error.missingCartInformation' }));
        return;
      }

      const redeemTokensValue = tokens ? extractNumberFromNumericFormat(tokens).toString() : '';
      let hasErrors = false;

      if (redeemTokensValue) {
        if (+tokens > tokenBalance) {
          hasErrors = true;
          setError('error.redeemTokensExceedTokenBalance');
        }

        if (+tokensValue > +remainingCartTotal.amount + +currentRedeemValue) {
          hasErrors = true;
          setError('error.redeemAmountExceedCartValue');
        }

        if (!hasErrors) {
          saveRedemptionAmountToCart(`${redeemTokensValue} ${PointCurrency.Systkn}`, fbUser);
        }
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRedeemTokensChanges = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setTokens(value);
  };

  return (
    <>
      <Typography variant="body1" mb={1.5}>
        {`${intl.formatMessage({ id: 'label.totalBalance' })}: ${tokenBalance.toLocaleString()}`}
      </Typography>
      <Box display="flex" alignItems="center" gap={1} mb={1.5}>
        <NumberField
          name="redeemTokens"
          value={tokens}
          onChange={handleRedeemTokensChanges}
          error={!!error}
          helperText={error ? intl.formatMessage({ id: error }) : ''}
          autoComplete="off"
          placeholder={intl.formatMessage({ id: 'placeholder.enterTokenAmount' })}
          disabled={isDisabled}
          variant="outlined"
          fullWidth
          decimalScale={0}
        />
        <Button
          variant="outlined"
          onClick={saveRedeemTokens}
          loading={isSubmitting || isLoadingTokens}
          disabled={isDisabled}
          sx={{ mb: error ? 2 : 0 }}
        >
          {intl.formatMessage({ id: 'button.apply' })}
        </Button>
      </Box>
      <ValueWrapper>
        <Typography variant="subtitle2">
          {`${intl.formatMessage({ id: 'label.redeemValue' })}: ${formattedValue} ${Currency.USD}`}
        </Typography>
      </ValueWrapper>
    </>
  );
};

export default RedeemTokens;
