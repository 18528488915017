import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')<{ hasError?: boolean }>(({ theme, hasError }) => (hasError ? {
  '& .MuiFormHelperText-root': {
    color: theme.palette.error.main,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.error.main,
  },
} : {}));
