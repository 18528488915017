import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const Button = styled(MuiButton)(() => ({
  '&.MuiButton-root': {
    borderRadius: '10px',
    fontSize: '18px',
    lineHeight: '1.3',
    borderWidth: '2px',
    padding: '5px 10px',
    height: '54.5px',
  }
}));
