import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import envConfig from 'configuration/env';
import { TOKENS_REDEMPTION_VALUES } from 'constants/currency';

interface RedemptionProcessProps {
  onClose: () => void;
}

const RedemptionProcess: FC<RedemptionProcessProps> = ({ onClose }) => {
  const intl = useIntl();

  const renderTOS = (chunks: ReactNode[]) => (
    <a
      href={envConfig.tosUrl}
      target="_blank"
      data-qa="termsOfUseLink"
      rel="noreferrer"
      aria-label={intl.formatMessage({ id: 'label.termsOfService' })}
    >
      {chunks}
    </a>
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h1">
        {intl.formatMessage({ id: 'label.understandingTokenRedemption' })}
      </Typography>
      <Typography variant="body2">
        {intl.formatMessage({ id: 'label.yourTokensHoldASpecificDollar' })}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ pt: 1 }}>
              <Typography variant="h2">
                {intl.formatMessage({ id: 'label.tokensVolume' })}
              </Typography>
            </TableCell>
            <TableCell sx={{ pt: 1 }}>
              <Typography variant="h2">
                {intl.formatMessage({ id: 'label.valuePerToken' })}
              </Typography>
            </TableCell>
            <TableCell sx={{ pt: 1 }}>
              <Typography variant="h2">
                {intl.formatMessage({ id: 'label.gain' })}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {TOKENS_REDEMPTION_VALUES.map((tokenRedemption) => {
            const min = tokenRedemption.min ? tokenRedemption.min?.toLocaleString() : '';
            const max = tokenRedemption.max ? tokenRedemption.max?.toLocaleString() : '';
            const tokensVolume = !max ? `${min}+` : `${min}-${max}`;
            const gain = tokenRedemption.gain ? `${tokenRedemption.gain}%` : '--';

            return (
              <TableRow key={tokenRedemption.id}>
                <TableCell>{tokensVolume}</TableCell>
                <TableCell>{`$${tokenRedemption.value}`}</TableCell>
                <TableCell>{gain}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Typography variant="body2" mt={1}>
        {intl.formatMessage({ id: 'label.theMoreTokensYouRedeem' })}
      </Typography>
      <Typography variant="body2">
        {intl.formatMessage({ id: 'label.pleaseNoteThatTheFinalDollar' })}
      </Typography>
      <Typography variant="body2">
        {intl.formatMessage(
          { id: 'label.kindlyReferToOurComprehensive' },
          { c: renderTOS },
        )}
      </Typography>
      <Button variant="contained" className="big" onClick={onClose}>
        {intl.formatMessage({ id: 'button.close' })}
      </Button>
    </Box>
  );
};

export default RedemptionProcess;
