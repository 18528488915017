import { alpha } from '@mui/material';
import MuiButton from '@mui/material/Button';
import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ isDark?: boolean }>(({ theme, isDark = false }) => ({
  padding: theme.spacing(1),
  borderRadius: '12px',
  backgroundColor: isDark ? alpha(theme.palette.common.black, 0.2) : theme.palette.common.white,

  '& .MuiDivider-root': {
    borderColor: isDark ? alpha(theme.palette.common.white, 0.5) : alpha(theme.palette.common.black, 0.4),
  },

  '& .subtitle': {
    color: alpha(theme.palette.common.black, 0.7),
  }
}));

export const CardMedia = styled(CardMediaMui)(() => ({
  width: '40px',
  objectFit: 'cover',
  borderRadius: '8px',
  height: '40px',
})) as typeof CardMediaMui;

export const Button = styled(MuiButton)(({ theme }) => ({
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  paddingLeft: theme.spacing(2.5),

  '& .MuiButton-icon': {
    marginLeft: 0,
  },
}));
