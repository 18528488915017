import { Box } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Root, Badge } from 'containers/ProductDetails/BottomNavigation/index.styled';
import { FbUserContext } from 'context/FbUserContext';
import { ActionButton } from 'layouts/BountyContentLayout/BottomNavigation/index.styled';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { totalProductsQuantitySelector } from 'store/cart/selectors';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';
import { setShowDownloadOverlay } from 'store/system/systemSlice';

import AccountCircle from 'assets/icons/AccountCircle';
import ArrowBack from 'assets/icons/ArrowBack';
import Download from 'assets/icons/Download';
import ShoppingCart from 'assets/icons/ShoppingCart';
import Wallet from 'assets/icons/Wallet';
import { CHECKOUT_ROUTE, WALLET_ROUTE } from 'constants/clientRoutes';
import { formatCartTotalQuantity } from 'utils/formatters';

const BottomNavigation = () => {
  const navigate = useNavigate();
  const { fbUser } = useContext(FbUserContext);
  const dispatch = useAppDispatch();
  const totalProductsQuantity = useAppSelector(totalProductsQuantitySelector);
  
  const handlePrev = () => navigate(-1);

  const handleSeeCheckout = () => navigate(CHECKOUT_ROUTE);

  const handleSeeWallet = () => navigate(WALLET_ROUTE);

  const handleShowDownloadOverlay = () => dispatch(setShowDownloadOverlay(true));

  const handleSignIn = () => dispatch(openModalSignIn());

  return (
    <Root justifyContent="space-between">
      <ActionButton onClick={handlePrev}>
        <ArrowBack color="white" size={18} />
      </ActionButton>
      <Box display="flex" alignItems="center" gap={1}>
        <ActionButton onClick={handleSeeWallet}>
          <Wallet color="white" size={18} />
        </ActionButton>
        {totalProductsQuantity > 0 && (
          <ActionButton onClick={handleSeeCheckout}>
            <Badge badgeContent={formatCartTotalQuantity(totalProductsQuantity)} color="primary">
              <ShoppingCart color="white" size={18} />
            </Badge>
          </ActionButton>
        )}
        {!fbUser?.isAnonymous ? (
          <ActionButton onClick={handleShowDownloadOverlay}>
            <Download color="white" size={18} />
          </ActionButton>
        ) : (
          <ActionButton onClick={handleSignIn}>
            <AccountCircle color="white" size={18} />
          </ActionButton>
        )}
      </Box>
    </Root>
  );
};

export default BottomNavigation;
