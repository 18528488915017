import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { CurrencyTypes, PointCurrency } from 'constants/enums';
import { Money, Point, Reward } from 'models/money.interface';
import { addMoney, getAmountAsBigDecimal, isZeroMoney, multiplyMoney } from 'utils/money';
import { addPoints, getNormalizedPoints, getPointsAmount, isZeroPoints, sumOfAbs } from 'utils/point';
import { isValid, stringToVoucher, voucherToString } from 'utils/voucher';

export const stringToReward = (str: string) => {
  if (!str || !str.trim().length) {
    return null;
  }

  const reward: Reward = {};
  const parts = str.split(',');

  parts.forEach((part) => {
    part = part.trim();

    if (part.startsWith('vouchers:')) {
      const vouchersStr = part.substring(part.indexOf(':') + 1).split('__');
      vouchersStr.forEach((s) => {
        const voucher = stringToVoucher(s);

        if (voucher) {
          reward.vouchers = {
            ...(reward.vouchers || {}),
            [voucherToString(voucher)]: voucher,
          };
        }
      });
    } else if (part.startsWith('promotions:')) {
      // const promotionsStr = part.substring(part.indexOf(':') + 1).split('__');
      // for (String s : promotionsStr) {
      //   s = s.trim();
      //   reward.addPromotion(Promotion.valueOf(s));
      // }
    } else if (part.startsWith('badge:')) {
      // reward.addBadge(Badges.Badge.valueOf(part.substring(part.indexOf(":") + 1)));
    } else if (part.startsWith('badges:')) {
      // const badgesStr = part.substring(part.indexOf(':') + 1).split('__');
      // for (String s : badgesStr) {
      //   s = s.trim();
      //   reward.addBadge(Badges.Badge.valueOf(s));
      // }
    } else {
      const bits = part.split(' ');
      if (bits.length === 2) {
        // PointCurrency pc = PointCurrency.asEnum(bits[1]);
        //
        // if (pc != PointCurrency.NONE && pc != PointCurrency.UNKNOWN) {
        //   int amount = new BigDecimal(bits[0]).setScale(0, RoundingMode.HALF_UP).intValueExact();
        //   Points pt = new Points(amount, pc);
        //   reward.addPoints(pt);
        // } else {
        //   Currency mc = Currency.asEnum(bits[1]);
        //   if (mc != Currency.NONE && mc != Currency.UNKNOWN) {
        //     BigDecimal amount = new BigDecimal(bits[0]);
        //     Money money = new Money(amount, mc);
        //     if (reward.hasMoney()) {
        //       throw new IllegalArgumentException("Already has money: " + part);
        //     }
        //     reward.setMoney(money);
        //   } else {
        //     throw new IllegalArgumentException("Unknown part: " + part);
        //   }
        // }
      }
    }
  });

  return reward;
};

export const formatMoney = (
  money: Money,
  formal: boolean | undefined,
  defaultValue: string,
  enforceDecimal?: boolean,
) => {
  if (!money || !money.amount) {
    return defaultValue;
  }

  const amountAsDec: string | any = getAmountAsBigDecimal(money.amount, enforceDecimal);

  let amount;
  switch (money.currency) {
    case CurrencyTypes.EUR:
      amount = `${amountAsDec} EUR`;
      break;
    case CurrencyTypes.GBP:
      amount = `£${amountAsDec}`;
      break;
    case CurrencyTypes.CAD:
      amount = `$${amountAsDec} CAD`;
      break;
    case CurrencyTypes.USD:
      amount = `${amountAsDec} USD`;
      break;
    case CurrencyTypes.NONE:
      amount = amountAsDec.toString();
      break;

    default: {
      if (money.currency) {
        amount = `${amountAsDec} ${money.currency}`;
      } else {
        amount = amountAsDec.toString();
      }
    }
  }

  if (!formal) {
    amount = amount.replace('[.]0*$', '');
  }

  return amount;
};

const formatSystemTokens = (value: number) => value === 1
  ? '1 Token'
  : `${new Intl.NumberFormat('en-IN').format(value)} Tokens`;

export const formatReward = (
  reward: Reward | undefined,
  defaultValue: string = '-',
  enforceDecimal: boolean = false,
) => {
  let moneyStr = null;
  let pointStr = null;

  if (!reward) {
    return defaultValue;
  }

  if (reward.money && !isZeroMoney(reward.money)) {
    moneyStr = formatMoney(reward.money, false, defaultValue, enforceDecimal);
  }

  if (reward.points && sumOfAbs(reward.points) > 0) {
    const stars = getNormalizedPoints(reward.points, PointCurrency.Star);

    if (stars > 0) {
      pointStr = stars === 1 ? 'one star' : `${stars} stars`;
    } else {
      const points = getPointsAmount(reward, PointCurrency.Systkn);
      const currencyCode = Object.keys(reward.points)[0];

      if (currencyCode !== PointCurrency.Systkn) {
        pointStr = `${new Intl.NumberFormat('en-IN').format(points)} ${currencyCode}`;
      } else {
        pointStr = formatSystemTokens(points);
      }
    }
  }

  const arr = [];

  if (moneyStr && moneyStr.trim()) {
    arr.push(moneyStr);
  }

  if (pointStr && pointStr.trim()) {
    arr.push(pointStr);
  }

  return arr.join(' + ');
};

export const formatFundraiserGoal = (reward: Reward) => {
  let moneyStr = null;
  let pointStr = null;

  if (reward.money && !isZeroMoney(reward.money)) {
    moneyStr = `${getAmountAsBigDecimal(reward.money.amount, false)} ${reward.money.currency}`;
  }

  if (reward.points && sumOfAbs(reward.points) > 0) {
    const stars = getNormalizedPoints(reward.points, PointCurrency.Star);

    if (stars > 0) {
      pointStr = stars === 1 ? 'one star' : `${stars} stars`;
    } else {
      const points = getPointsAmount(reward, PointCurrency.Systkn);
      const currencyCode = Object.keys(reward.points)[0];

      if (currencyCode !== PointCurrency.Systkn) {
        pointStr = `${points} ${currencyCode}`;
      } else {
        pointStr = formatSystemTokens(points);
      }
    }
  }

  const arr = [];

  if (moneyStr && moneyStr.trim()) {
    arr.push(moneyStr);
  }

  if (pointStr && pointStr.trim()) {
    arr.push(pointStr);
  }

  return arr.join(', ');
};

export const formatTokens = (amount: number, currencyCode?: PointCurrency) => {
  if (currencyCode !== PointCurrency.Systkn) {
    return `${new Intl.NumberFormat('en-IN').format(amount)} ${currencyCode}`;
  }
  return formatSystemTokens(amount);
};

export const hasMoney = (reward: Reward, allowZero = false) => {
  const amount = reward.money?.amount;

  return reward && reward.money && !Number.isNaN(amount ? +amount : amount) &&
    (allowZero || !isZeroMoney(reward.money));
};

export const hasPoints = (reward: Reward) => (
  reward && reward.points && Object.keys(reward.points).length > 0 && sumOfAbs(reward.points) > 0
);

export const hasVoucher = (reward: Reward) => {
  if (!reward || !reward.vouchers || !Object.keys(reward.vouchers).length) {
    return false;
  }

  return Object.values(reward.vouchers).some((voucher) => isValid(voucher));
};

export const hasBadge = (reward: Reward) => reward && reward.badges;

export const isRewardEmpty = (reward: Reward) => (
  !hasMoney(reward) && !hasPoints(reward) && !hasVoucher(reward) && !hasBadge(reward)
);

const multiplyPts = (point: Point, quantity: number): Point | null => {
  if (!point || !quantity) {
    return null;
  }

  if (isZeroPoints(point)) {
    return point;
  }

  return {
    amount: Number(point.amount) * quantity,
    currency: point.currency,
  };
};

const multiplyPoints = (points?: Record<string, Point> | null, quantity?: number): Record<string, Point> | null => {
  const result = {};

  if (!points || !quantity) {
    return null;
  }

  if (!Object.keys(points).length) {
    return points;
  }

  Object.keys(points).forEach((pointType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    result[pointType] = multiplyPts(points[pointType], quantity);
  });

  return result;
};

export const multiply = (reward?: Reward, quantity?: number) => {
  const result: {
    points: any;
    money?: Money;
  } = {
    points: undefined,
  };

  if (!reward || !quantity) {
    return null;
  }

  if (isRewardEmpty(reward)) {
    return reward;
  }

  if (hasMoney(reward) && reward.money) {
    result.money = multiplyMoney(reward.money, quantity);
  }
  if (hasPoints(reward)) {
    result.points = multiplyPoints(reward.points, quantity);
  }

  return result;
};

export const add = (existingReward: Reward | null, rewardToAdd: Reward | null): Reward | null => {
  if (!existingReward) {
    return rewardToAdd;
  }

  if (!rewardToAdd || isRewardEmpty(rewardToAdd)) {
    return existingReward;
  }

  if (isRewardEmpty(rewardToAdd)) {
    return rewardToAdd;
  }

  if (hasVoucher(existingReward) && hasVoucher(rewardToAdd)) {
    toast.error(<FormattedMessage id="reward.maxOneVoucher" />);
    return null;
  }

  if (hasBadge(existingReward) && hasBadge(rewardToAdd)) {
    toast.error(<FormattedMessage id="reward.maxOneBadge" />);
    return null;
  }

  if (hasMoney(existingReward) && hasMoney(rewardToAdd)
    && existingReward?.money?.currency !== rewardToAdd?.money?.currency
  ) {
    toast.error(<FormattedMessage id="reward.maxOneCurrency" />);
    return null;
  }

  return {
    money: addMoney(existingReward.money, rewardToAdd.money) || undefined,
    points: addPoints(existingReward.points, rewardToAdd.points) || null,
    vouchers: existingReward.vouchers || rewardToAdd.vouchers,
    badges: existingReward.badges || rewardToAdd.badges,
  };
};

export const sum = (rewards: Reward[] | null) => {
  if (!rewards || !rewards.length) {
    return {};
  }

  if (rewards.length === 1) {
    return rewards[0];
  }

  let result: Reward | null = {};

  rewards.forEach((reward) => {
    result = add(result, reward);
  });

  return result;
};
