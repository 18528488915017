import { User } from 'firebase/auth';
import { DatabaseReference } from 'firebase/database';
import { isEqual } from 'lodash';

import {
  BountyResponseState,
  BountyType,
  IdentityType,
  OutboundResponseRefType,
  ResponseType,
  VisibilityModeType,
  WinnerSelectionMethod
} from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { BountyResponse } from 'models/bountyResponse.interface';
import { Changes } from 'models/changes.interface';
import { VisibilityMode } from 'models/visibilityMode.interface';

export const getResponseType = (bounty: Bounty): ResponseType => {
  switch (bounty.type) {
    case BountyType.TalentSearch:
    case BountyType.RealEstateCustomer:
      return ResponseType.Recommendation;
    default:
      return ResponseType.Note;
    }
};

export const isAllowedToDelete = (state: BountyResponseState): boolean => [
  BountyResponseState.Draft,
  BountyResponseState.Retracted,
  BountyResponseState.Rejected,
  BountyResponseState.Refused,
].includes(state);

export const isDraftState = (state: BountyResponseState): boolean => (
  [
    BountyResponseState.None,
    BountyResponseState.Draft,
    BountyResponseState.Pending,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
  ].includes(state)
);

export const getOutboundResponse = (bountyResponse: BountyResponse): BountyResponse => {
  if (bountyResponse?.identityMode?.identityType === IdentityType.Real) {
    return {
      ...bountyResponse,
      author: null,
    };
  }

  return bountyResponse;
};

export const isAllowedToPublishResponse = (state: BountyResponseState): boolean => (
  [
    BountyResponseState.Draft,
    BountyResponseState.Retracted,
    BountyResponseState.Rejected,
  ].includes(state)
);

export const getPriorityReference = (type: OutboundResponseRefType, ref: DatabaseReference) => {
  if (type === OutboundResponseRefType.sentItems) {
    return ref.parent;
  }

  return ref;
};

export const isCreatedByMe = (data: BountyResponse, user: User): boolean => (
  data.creator && user && data.creator.id === user?.uid
);

export const getChangedObj = (oldValue: unknown, newValue: unknown): Changes => ({
  oldValue: oldValue || null,
  newValue: newValue || null,
});

export const getResponseUpdates = (origResponse: BountyResponse, newResponse: BountyResponse) => {
  const updates: Record<string, unknown> = {};
  const changes: Record<string, Changes> = {};

  if (origResponse.note !== newResponse.note) {
    updates.note = newResponse.note;
    changes.note = getChangedObj(origResponse.note, newResponse.note);
  }

  if (!isEqual(origResponse.attachments, newResponse.attachments)) {
    updates.attachments = newResponse.attachments;
    changes.attachments = getChangedObj(origResponse.attachments, newResponse.attachments);
  }

  if (origResponse.identityMode && newResponse.identityMode
    && (origResponse.identityMode.forced !== newResponse.identityMode.forced
      || origResponse.identityMode.identityType !== newResponse.identityMode.identityType)) {
    updates.identityMode = newResponse.identityMode;
    changes.identityMode = getChangedObj(origResponse.identityMode, newResponse.identityMode);
  }

  if (origResponse.visibilityMode !== newResponse.visibilityMode) {
    updates.visibilityMode = newResponse.visibilityMode;
    changes.visibilityMode = getChangedObj(origResponse.visibilityMode, newResponse.visibilityMode);
  }

  return { updates, changes };
};

export const getLevel1Id = (response: BountyResponse) => response?.bountyInfo?.id || null;

export const getLevel2Id = (response: BountyResponse) => response.id;

export function areRepliesPrivate(responseVisibilityMode: VisibilityMode) {
  return responseVisibilityMode && responseVisibilityMode.visibilityType === VisibilityModeType.Private;
}

export const getResponsesTitle = (bounty: Bounty) => {
  const { challengeInfo, responseVisibilityMode } = bounty;

  switch (challengeInfo?.winnerSelectionMethod) {
    case WinnerSelectionMethod.Manual: {
      const privateReplies = responseVisibilityMode ? areRepliesPrivate(responseVisibilityMode) : false;
      return privateReplies ? 'label.repliesTitlePrivateManual' : 'label.repliesTitlePublicManual';
    }
    case WinnerSelectionMethod.LikesThreshold:
      return 'label.repliesTitleLikesThreshold';
    case WinnerSelectionMethod.LikesWeightedRandom:
      return 'label.repliesTitleLikesWeightedRandom';
    default:
      return 'label.replies';
  }
};

export const isResponseApproved = (outboundResponses: Record<string, BountyResponse>) => {
  if (!outboundResponses) {
    return false;
  }

  const activeResponses = Object
    .values(outboundResponses)
    .filter(({ state }) => (
      ![BountyResponseState.Deleted, BountyResponseState.Declined, BountyResponseState.Rejected].includes(state)
    ));

  return Boolean(activeResponses?.length);
};

export const getSurveyAnswer = (outboundResponses?: Record<string, BountyResponse>) => {
    if (!outboundResponses) {
      return null;
    }

    return Object
      .values(outboundResponses)
      .find(({ type }) => type === ResponseType.Choice);
};
