import { Box, Button, Typography } from '@mui/material';
import { onValue, query } from 'firebase/database';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import StoryDialog from 'components/StoryDialog';
import StripeProvider from 'components/StripeProvider';
import TestCC from 'components/TestCC';
import AddCreditCard from 'containers/AddCreditCard';
import ChoosePayment from 'containers/Checkout/ChoosePayment';
import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { getUserPaymentsRef } from 'services/FirebaseRefs';
import { savePaymentToCart } from 'services/ShoppingCart';
import { cartSelector, isLoadingSelector } from 'store/cart/selectors';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';

import { CardInfo } from 'models/cardInfo.interface';
import { getNewestCreditCard } from 'utils/card';

enum DialogType {
  Add = 'Add',
  Choose = 'Choose',
}

const PaymentDetails = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { fbUser } = useContext(FbUserContext);
  const { paymentInfo } = useAppSelector(cartSelector);
  const isLoadingCart = useAppSelector(isLoadingSelector);
  const [payments, setPayments] = useState<Record<string, CardInfo>>({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.Add);

  useEffect(() => {
    if (!paymentInfo && Object.keys(payments)?.length && !isLoadingCart) {
      const payment = getNewestCreditCard(payments);

      if (payment && fbUser) {
        savePaymentToCart(payment, fbUser);
      }
    }
  }, [paymentInfo, payments, fbUser, isLoadingCart]);

  useEffect(() => {
    if (fbUser?.uid) {
      const refQuery = query(getUserPaymentsRef(fbUser?.uid));
      onValue(
        refQuery,
        (dataSnapshot) => {
          const userPayments = dataSnapshot.val() || {};
          setPayments(userPayments);
        },
        () => setPayments({}));
    }
  }, [fbUser?.uid]);

  const handleOpenDialog = (type: DialogType) => {
    if (fbUser?.isAnonymous) {
      dispatch(openModalSignIn());
      return;
    }

    setIsDialogOpen(true);
    setDialogType(type);
  };

  const handleCloseDialog = () => setIsDialogOpen(false);

  const handleAddPayment = (payment: CardInfo) => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    savePaymentToCart(payment, fbUser);
    handleCloseDialog();
  };

  return (
    <div>
      {paymentInfo
        ? (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography
              variant="body1"
              color="primary"
              fontWeight={600}
              className="pointer"
              onClick={() => handleOpenDialog(DialogType.Choose)}
            >
              {intl.formatMessage(
                { id: 'label.paymentTypeEndingInDigits' },
                { paymentType: paymentInfo?.cardType, digits: paymentInfo?.redactedNumber?.replaceAll('*', '') }
              )}
            </Typography>
            {!paymentInfo?.liveMode && <TestCC />}
          </Box>
        ): (
          <Button variant="text" onClick={() => handleOpenDialog(DialogType.Add)}>
            {intl.formatMessage({ id: 'button.addAPaymentMethod' })}
          </Button>
        )}

      <StoryDialog isOpen={isDialogOpen} onClose={handleCloseDialog}>
        {dialogType === DialogType.Choose && paymentInfo && (
          <ChoosePayment
            payments={payments}
            currentPayment={paymentInfo}
            onClose={handleCloseDialog}
            onAddPayment={() => handleOpenDialog(DialogType.Add)}
          />
        )}
        {dialogType === DialogType.Add && fbUser && (
          <StripeProvider>
            <AddCreditCard onSuccess={handleAddPayment} />
          </StripeProvider>
        )}
      </StoryDialog>
    </div>
  );
};

export default PaymentDetails;
