import { SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Root } from 'containers/Checkout/PriceSection/index.styled';

interface PriceSectionProps {
  label: string | ReactNode[];
  value: string;
  isBold?: boolean;
  sx?: SxProps;
}

const PriceSection: FC<PriceSectionProps> = ({
  label,
  value,
  isBold = false,
  sx = {},
}) => (
  <Root sx={sx}>
    <Typography variant="body1" fontWeight={isBold ? 700 : 400}>
      {label}
    </Typography>
    <Typography variant="body1" fontWeight={isBold ? 700 : 400}>
      {value}
    </Typography>
  </Root>
);

export default PriceSection;
