import DefaultCover from 'assets/images/bkg-img.png';
import PatternChallenge from 'assets/patterns/pattern-challenge.png';
import PatternFundraiser from 'assets/patterns/pattern-fundraiser.png';
import PatternLottery from 'assets/patterns/pattern-lottery.png';
import PatternPoll from 'assets/patterns/pattern-poll.png';
import PatternQuiz from 'assets/patterns/pattern-quiz.png';
import PatternTrivia from 'assets/patterns/pattern-trivia.png';
import { BountyType, PatternType } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';

export const getCampaignCover = (bounty: Bounty) => {
  const attachments = bounty?.attachments?.attachments ? Object.values(bounty?.attachments?.attachments) : [];

  if (bounty?.type === BountyType.ExpressCampaign && !attachments?.length) {
    switch (bounty?.theme?.colorScheme?.patternType) {
      case PatternType.Chessboard:
        return {
          coverImage: PatternChallenge,
          showEmptyState: true,
        };
      case PatternType.DiagonalLines:
        return {
          coverImage: PatternFundraiser,
          showEmptyState: true,
        };
      case PatternType.QuarterCircles:
        return {
          coverImage: PatternLottery,
          showEmptyState: true,
        };
      case PatternType.HorizontalLines:
        return {
          coverImage: PatternPoll,
          showEmptyState: true,
        };
      case PatternType.FourDots:
        return {
          coverImage: PatternQuiz,
          showEmptyState: true,
        };
      case PatternType.DownwardArrows:
        return {
          coverImage: PatternTrivia,
          showEmptyState: true,
        };
      default:
        break;
    }
  }

  return {
    coverImage: bounty?.attachments?.attachments
      ? attachments?.[0]?.cdnUrl || attachments?.[0]?.url
      : DefaultCover,
    showEmptyState: false,
  };
};
