import { styled } from '@mui/material';

import CustomDropdownField from 'fields/DropdownField';
import CustomInputField from 'fields/InputField';

export const InputField = styled(CustomInputField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const DropdownField = styled(CustomDropdownField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.common.white,
  },
}));
