import { Box, IconButton, MobileStepper } from '@mui/material';
import { FC, useReducer } from 'react';
import { useSwipeable } from 'react-swipeable';
import { SwipeEventData } from 'react-swipeable/src/types';

import Attachment from 'components/Attachment';
import {
  CarouselContainer,
  CarouselSlot,
  Item,
  Root,
  SlideButtonContainer,
  Wrapper,
} from 'components/Carousel/index.styled';
import {
  Direction, NEXT, PREV, reducer, getInitialState,
} from 'components/Carousel/reducer';
import VideoPlayer from 'containers/VideoPlayer';

import useStoryDimensions from 'hooks/useStoryDimensions';

import ArrowBack from 'assets/icons/ArrowBack';
import ArrowForward from 'assets/icons/ArrowForward';
import { xContentPadding } from 'assets/theme/customSpacing';
import { AttachmentType } from 'constants/enums';
import { Attachment as AttachmentInterface } from 'models/attachment.interface';
import { getAttachmentUrl } from 'utils/attachments';

interface CarouselProps {
  attachments: AttachmentInterface[];
  offset?: number;
  classNameStepper?: string;
}

const getOrder = (index: number, pos: number, numItems: number) => (
  index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos
);

const Carousel:FC<CarouselProps> = ({ attachments, offset = 56 , classNameStepper = '' }) => {
  const numItems = attachments.length;
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { width } = useStoryDimensions();
  const imageSize = `${width - (offset + (2 * xContentPadding))}px`;

  const slide = (dir: Direction) => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handleSwipeLeft = (eventData: SwipeEventData) => {
    if (state.pos < numItems - 1) {
      eventData.event.stopPropagation();
      slide(NEXT);
    }
  };

  const handleSwipeRight = (eventData: SwipeEventData) => {
    if (state.pos > 0) {
      eventData.event.stopPropagation();
      slide(PREV);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    rotationAngle: 30,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Root width={imageSize}>
      <Box position="relative" {...handlers}>
        <Wrapper imageSize={imageSize} className="carouselWrapper">
          <CarouselContainer dir={state.dir} sliding={state.sliding}>
            {attachments.map((attachment, index) => {
              const { url, isManifest } = getAttachmentUrl(attachment);

              return (
                <CarouselSlot
                  key={index}
                  order={getOrder(index, state.pos, numItems)}
                >
                  <Item />
                  {[AttachmentType.Audio, AttachmentType.Video].includes(attachment?.type) ? (
                    <VideoPlayer
                      controls
                      url={url}
                      width={imageSize}
                      height={imageSize}
                      isPlaying={state.pos === index}
                      isManifest={isManifest}
                    />
                  ) : (
                    <Attachment
                      url={url}
                      type={attachment?.type}
                      height={imageSize}
                      width={imageSize}
                    />
                  )}
                  <Attachment
                    url={url}
                    type={attachment?.type}
                    height={imageSize}
                    width={imageSize}
                  />
                </CarouselSlot>
              );
            })}
          </CarouselContainer>
        </Wrapper>
        {numItems > 1 && (
          <SlideButtonContainer>
            <div>
              <IconButton
                onClick={() => slide(PREV)}
              >
                <ArrowBack />
              </IconButton>
            </div>
            <div>
              <IconButton
                onClick={() => slide(NEXT)}
              >
                <ArrowForward />
              </IconButton>
            </div>
          </SlideButtonContainer>
        )}
      </Box>
      {numItems > 1 && (
        <MobileStepper
          steps={numItems}
          position="static"
          sx={{ justifyContent: 'center' }}
          activeStep={state.pos}
          backButton={null}
          nextButton={null}
          className={classNameStepper}
        />
      )}
    </Root>
  );
};

export default Carousel;
