import { alpha, Typography } from '@mui/material';
import CardMediaMui from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: '10px',
  height: '80px',
  width: '80px',
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    height: '60px',
    width: '60px',
  },
})) as typeof CardMediaMui;

export const EmptyBackground = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.black, 0.2),
  height: '80px',
  width: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  cursor: 'pointer',

  [theme.breakpoints.down('sm')]: {
    height: '60px',
    width: '60px',
  },
}));

export const Description = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}));
