import { styled } from '@mui/material/styles';

import { mainContentOffset, mainContentOffsetMobile } from 'assets/theme/customSpacing';

export const Page = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: `calc(100% - ${mainContentOffset}px)`,

  [theme.breakpoints.down('md')]: {
    height: `calc(100% - ${mainContentOffsetMobile}px)`,
  },
}));

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  backgroundColor: theme.palette.general.darkGrey4,
  color: theme.palette.text.primary,
}));

export const TabContent = styled('div')(({ theme }) => ({
  height: 'calc(100vh - 420px)',
  padding: theme.spacing(2.5)
}));
