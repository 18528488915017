import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, ReactNode, useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import PriceSection from 'containers/Checkout/PriceSection';
import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { calculateOrderSummary } from 'services/ShoppingCart';
import { setHasCouponAppliedSuccessfully } from 'store/cart/cartSlice';
import { cartSelector, hasCouponAppliedSuccessfullySelector } from 'store/cart/selectors';

import { DiscountType, OrderSectionType, PointCurrency } from 'constants/enums';
import { EMPTY_REWARD } from 'constants/general';
import { DiscountInfo } from 'models/discount.interface';
import { OrderSection, OrderSummaryResult } from 'models/order.interface';
import { getFirstNonNullSection } from 'utils/cart';
import { handleApiErrors } from 'utils/error';
import { formatMoney, formatReward } from 'utils/reward';

interface SummaryProps {
  inAppSection: OrderSection | null;
  setInAppSection: (inAppSection: OrderSection | null) => void;
  onFailSummary: (hasFailed: boolean) => void;
}

const Summary: FC<SummaryProps> = ({
  inAppSection,
  onFailSummary,
  setInAppSection
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { fbUser } = useContext(FbUserContext);
  const cart = useAppSelector(cartSelector);
  const hasCouponAppliedSuccessfully = useAppSelector(hasCouponAppliedSuccessfullySelector);
  const {
    itemsTotal,
    total,
    discounts,
    taxes,
    deliveryFee,
    totalRedeemed,
    totalExchanged,
  } = inAppSection || {};

  const getInAppSections = (summary: OrderSummaryResult) => {
    const inApp = getFirstNonNullSection(summary, [OrderSectionType.InApp]);
    setInAppSection(inApp);
  };

  useEffect(() => {
    if (cart.products && Object.keys(cart.products).length && fbUser?.uid) {
      fetchSummary();
    }
  }, [cart, fbUser]);

  const fetchSummary = async () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    try {
      const response = await calculateOrderSummary(cart, fbUser);

      if (cart?.coupon) {
        const orderSection = getFirstNonNullSection(response?.data, [OrderSectionType.InApp]);

        if (!hasCouponAppliedSuccessfully) {
          if (orderSection && orderSection?.discounts?.length > 0) {
            dispatch(setHasCouponAppliedSuccessfully(true));
            toast.success(intl.formatMessage({ id: 'label.promoCodeSuccessfully' }));
          } else {
            toast.info(intl.formatMessage({ id: 'label.invalidPromoCode' }));
          }
        }
      }

      getInAppSections(response?.data);
      onFailSummary(false);
    } catch (e) {
      onFailSummary(true);
      setInAppSection(null);
      handleApiErrors(e);
    }
  };

  const renderBoldChunks = (chunks: ReactNode[]) => <b key="bold">{chunks}</b>;

  const getDiscountLabel = ({ name, type }: Partial<DiscountInfo>) => {
    if (type === DiscountType.Delivery) {
      return intl.formatMessage({ id: 'label.shippingDiscount' }, { b: renderBoldChunks });
    }

    return intl.formatMessage({ id: 'label.couponName' }, { name, b: renderBoldChunks });
  };

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <PriceSection
        label={intl.formatMessage({ id: 'label.itemTotal' })}
        value={itemsTotal?.money && !itemsTotal?.points
          ? formatMoney(itemsTotal.money, false, EMPTY_REWARD)
          : formatReward(itemsTotal, EMPTY_REWARD)}
      />
      {taxes && taxes.map(({ name, amount }) => (
        <PriceSection
          key={name}
          label={name}
          value={formatReward(amount, EMPTY_REWARD)}
        />
      ))}
      {!isEmpty(deliveryFee) && (
        <PriceSection
          label={intl.formatMessage({ id: 'label.shippingFee' })}
          value={formatReward(deliveryFee, EMPTY_REWARD)}
        />
      )}
      {!isEmpty(totalRedeemed) && !isEmpty(totalExchanged) && (
        <PriceSection
          label={intl.formatMessage({ id: 'label.redeemTokensAmount' }, {
            amount: totalRedeemed?.points?.[PointCurrency.Systkn]?.amount?.toLocaleString(),
            b: renderBoldChunks
          })}
          value={`-${formatReward(totalExchanged, EMPTY_REWARD)}`}
        />
      )}
      {discounts && discounts.map(({ name, amount, type }) => (
        <PriceSection
          key={name}
          label={getDiscountLabel({ name, type })}
          value={formatReward(amount, EMPTY_REWARD)}
        />
      ))}
      <PriceSection
        label={intl.formatMessage({ id: 'label.totalCharged' })}
        value={formatReward(total, EMPTY_REWARD)}
        isBold
        sx={{ mt: 1 }}
      />
    </Box>
  );
};

export default Summary;
