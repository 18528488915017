import { styled } from '@mui/material/styles';

export const AddressWrapper = styled('div')<{ isActive: boolean }>(({ isActive, theme }) => ({
  padding: theme.spacing(1.75, 2),
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '8px',
  cursor: 'pointer',
  backgroundColor: isActive
    ? theme.palette.general.lightGrey5
    : theme.palette.common.white,

  '&:hover': {
    backgroundColor: theme.palette.general.lightGrey5,
  },
}));
