import { Box, Button, SelectChangeEvent, Typography } from '@mui/material';
import { push } from 'firebase/database';
import { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';

import { InputField, DropdownField } from 'containers/Checkout/AddAddress/index.styled';
import { getInitialAddress, validateAddress } from 'containers/Checkout/utils';
import PhoneNumberField from 'fields/PhoneNumberField';

import { getUserAddressesRef } from 'services/FirebaseRefs';
import { updateUserInfo } from 'services/User';

import { COUNTRIES_OPTIONS } from 'constants/countries';
import { Address, CreateAddress } from 'models/address.interface';
import { getClientId, getUserAgent } from 'utils/api';
import { handleApiErrors } from 'utils/error';
import { checkValidPhone } from 'utils/phoneUtils';

interface AddAddressProps {
  userId: string;
  onSuccess: (address: Address) => void;
  initialAddress?: Address;
}

const AddAddress: FC<AddAddressProps> = ({
  initialAddress,
  userId,
  onSuccess,
}) => {
  const intl = useIntl();
  const [address, setAddress] = useState<CreateAddress>(getInitialAddress(initialAddress));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const cleanFieldError = (fieldName: string) => {
    if (errors?.[fieldName]) {
      setErrors((prev) => {
        const newState = { ...prev };
        delete newState[fieldName];
        return newState;
      });
    }
  };

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    cleanFieldError(name);
    setAddress((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelect = ({ target: { name, value } }: SelectChangeEvent<unknown>) => {
    cleanFieldError(name);
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAddAddress = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { errors: formErrors, hasErrors } = validateAddress(address);

    if (hasErrors) {
      setErrors(formErrors);
      return;
    }

    try {
      setIsSubmitting(true);
      let ref = getUserAddressesRef(userId);

      if (!initialAddress?.id) {
        push(ref);
      }

      const newAddress = {
        ...initialAddress,
        ...address,
        id: initialAddress?.id || ref.key || '',
        phoneNumber: checkValidPhone(address?.phoneNumber) ? address?.phoneNumber : '',
      };
      const payload: any = {
        meta: {
          action: 'update_user',
          clientId: getClientId(),
          userId: userId,
          agent: getUserAgent(),
          restEvent: true,
        },
        address: newAddress,
      };

      await updateUserInfo(userId, payload);

      if (onSuccess) {
        onSuccess(newAddress);
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form noValidate onSubmit={handleAddAddress} style={{ width: '100%' }}>
      <Box display="flex" flexDirection="column" gap={2} mb={3}>
        <Typography variant="h2" mb={1}>
          {intl.formatMessage({ id: 'label.addAddress' })}
        </Typography>
        <InputField
          name="name"
          label={intl.formatMessage({ id: 'label.fullName' })}
          required
          variant="outlined"
          onChange={handleChange}
          value={address?.name}
          fullWidth
          placeholder={intl.formatMessage({ id: 'placeholder.johnDoe' })}
          tooltip={intl.formatMessage({ id: 'hint.fullName' })}
          error={!!errors?.name}
          helperText={errors?.name ? intl.formatMessage({ id: errors?.name }) : ''}
        />
        <PhoneNumberField
          name="phoneNumber"
          label={intl.formatMessage({ id: 'label.phoneNumber' })}
          rawNumber={address?.phoneNumber}
          onChange={handleChange}
          tooltip={intl.formatMessage({ id: 'hint.phoneNumber' })}
          fullWidth
          error={!!errors?.phoneNumber}
          helperText={errors?.phoneNumber ? intl.formatMessage({ id: errors?.phoneNumber }) : ''}
        />
        <InputField
          name="line1"
          label={intl.formatMessage({ id: 'label.addressOne' })}
          required
          variant="outlined"
          onChange={handleChange}
          value={address?.line1}
          fullWidth
          placeholder={intl.formatMessage({ id: 'placeholder.enterYourAddressOne' })}
          tooltip={intl.formatMessage({ id: 'hint.addressOne' })}
          error={!!errors?.line1}
          helperText={errors?.line1 ? intl.formatMessage({ id: errors?.line1 }) : ''}
        />
        <InputField
          name="line2"
          label={intl.formatMessage({ id: 'label.addressTwo' })}
          variant="outlined"
          onChange={handleChange}
          value={address?.line2}
          fullWidth
          placeholder={intl.formatMessage({ id: 'placeholder.enterYourAddressTwo' })}
          tooltip={intl.formatMessage({ id: 'hint.addressTwo' })}
          error={!!errors?.line2}
          helperText={errors?.line2 ? intl.formatMessage({ id: errors?.line2 }) : ''}
        />
        <DropdownField
          name="countryCode"
          variant="outlined"
          onChange={handleSelect}
          options={COUNTRIES_OPTIONS}
          value={address?.countryCode}
          fullWidth
          required
          size="small"
          label={intl.formatMessage({ id: 'label.country' })}
          placeholder={intl.formatMessage({ id: 'hint.selectYourCountry' })}
          error={!!errors?.countryCode}
          helperText={errors?.countryCode ? intl.formatMessage({ id: errors?.countryCode }) : ''}
        />
        <InputField
          name="state"
          variant="outlined"
          required
          label={intl.formatMessage({ id: 'label.state' })}
          onChange={handleChange}
          value={address?.state}
          placeholder={intl.formatMessage({ id: 'placeholder.selectNY' })}
          fullWidth
          error={!!errors?.state}
          helperText={errors?.state ? intl.formatMessage({ id: errors?.state }) : ''}
        />
        <InputField
          name="city"
          required
          label={intl.formatMessage({ id: 'label.city' })}
          variant="outlined"
          onChange={handleChange}
          value={address?.city}
          placeholder={intl.formatMessage({ id: 'placeholder.newYork' })}
          fullWidth
          error={!!errors?.city}
          helperText={errors?.city ? intl.formatMessage({ id: errors?.city }) : ''}
        />
        <InputField
          label={intl.formatMessage({ id: 'label.postalCodeZipCode' })}
          required
          name="postalCode"
          variant="outlined"
          onChange={handleChange}
          value={address?.postalCode}
          placeholder={intl.formatMessage({ id: 'placeholder.postalCode' })}
          fullWidth
          tooltip={intl.formatMessage({ id: 'hint.postalCode' })}
          error={!!errors?.postalCode}
          helperText={errors?.postalCode ? intl.formatMessage({ id: errors?.postalCode }) : ''}
        />
      </Box>
      <Button
        className="big"
        fullWidth
        variant="contained"
        type="submit"
        loading={isSubmitting}
      >
        {intl.formatMessage({ id: 'button.save' })}
      </Button>
    </form>
  );
};

export default AddAddress;
