import { CreditCardTypes } from 'constants/enums';

const AMERICAN_EXPRESS_TYPES = [
  CreditCardTypes.Amex,
  CreditCardTypes.AmericanExpress,
  CreditCardTypes.American_Express,
];
const MASTER_CARD_TYPES = [
  CreditCardTypes.Mc,
  CreditCardTypes.MasterCard,
  CreditCardTypes.Master_Card,
];
const OTHER_TYPES = [
  CreditCardTypes.Generic,
  CreditCardTypes.Other,
];

export const cardTypeFromString = (cardType?: string) => {
  if (!cardType || !cardType.trim()) {
    return null;
  }

  const trimedCardType = cardType.trim().toUpperCase() as CreditCardTypes;

  if (AMERICAN_EXPRESS_TYPES.includes(trimedCardType)) {
    return CreditCardTypes.Amex;
  }
  if (MASTER_CARD_TYPES.includes(trimedCardType)) {
    return CreditCardTypes.Mc;
  }
  if (trimedCardType === CreditCardTypes.Visa) {
    return CreditCardTypes.Visa;
  }
  if (OTHER_TYPES.includes(trimedCardType)) {
    return CreditCardTypes.Generic;
  }

  return null;
};
