import { styled } from '@mui/material/styles';

import CustomDropdownField from 'fields/DropdownField';
import CustomInputField from 'fields/InputField';

export const StripeForm = styled('form')(({ theme }) => ({
  width: '100%',

  '& .StripeElement': {
    border: `1px solid ${theme.palette.general.borderColor}`,
    borderRadius: '5px',
    padding: '14px 14px',
  },

  '& .ElementsApp input': {
    fontSize: '18px',
  },

  '& .element': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
}));

export const InputField = styled(CustomInputField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
    borderRadius: '5px',
    fontSize: '18px',

    '&.Mui-focused fieldset': {
      borderWidth: '1px',
    },
  },

  '& fieldset': {
    border: `1px solid ${theme.palette.general.borderColor} !important`,
  },

  '& .MuiOutlinedInput-input': {
    padding: '12.75px 14px',
  },
}));

export const DropdownField = styled(CustomDropdownField)(({ theme }) => ({
  '& .MuiSelect-outlined': {
    backgroundColor: theme.palette.common.white,
    fontSize: '18px',
    padding: '16.75px 14px 10px',

    '&.Mui-focused fieldset': {
      borderWidth: '1px',
    },
  },

  borderRadius: '5px',

  '& fieldset': {
    border: `1px solid ${theme.palette.general.borderColor} !important`,
  },
}));
