import { Box, Button, Popover, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RewardType } from '~/constants/enums';
import { FC, MouseEvent, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import ProductPrice from 'components/ProductPrice';
import { Root } from 'containers/ProductDetails/PriceSection/index.styled';
import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch } from 'hooks/useRedux';
import { addProductToCart } from 'store/cart/cartSlice';

import { Bounty } from 'models/bounty.interface';
import { Reward } from 'models/money.interface';
import { getCurrencyType, getPrices, getTopLevelVariant } from 'utils/price';
import { formatReward } from 'utils/reward';

interface PriceSectionProps {
  bounty?: Bounty;
}

const PriceSection: FC<PriceSectionProps> = ({ bounty }) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isPopperOpen = Boolean(anchorEl);
  const { product } = bounty || {};
  const { fbUser } = useContext(FbUserContext);
  const { price, msrp } = product ? getPrices(product) : {};
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBuy = (event: MouseEvent<HTMLElement>) => {
    if (price && getCurrencyType(price) === RewardType.Either) {
      handleOpenPopper(event);
      return;
    }

    handleAddProduct(bounty);
  };

  const handleSelectPrice = (reward?: Reward) => {
    if (!reward || !product) {
      return;
    }

    const variant = getTopLevelVariant(product);

    if (!variant) {
      return;
    }

    const formattedBounty = {
      ...bounty,
      product: {
        ...product,
        variants: {
          [variant?.sku]: {
            ...variant,
            price: { mainPrice: reward },
          },
        },
      },
    } as Bounty;

    handleAddProduct(formattedBounty);
    handleClosePopper();
  };

  const handleAddProduct = (bounty?: Bounty) => {
    if (!bounty || !fbUser) {
      return;
    }

    dispatch(addProductToCart({ bounty, user: fbUser }));
    toast.success(intl.formatMessage({ id: 'label.productAddedToCart' }));
  };

  const handleOpenPopper = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      {price && bounty?.id && (
        <>
          <ProductPrice
            price={price}
            msrp={msrp}
            productId={bounty?.id}
            isActive={isMobile}
          />
          <Button onClick={handleBuy} variant="contained" className="big">
            {intl.formatMessage({ id: 'button.buy' })}
          </Button>
        </>
      )}
      {isPopperOpen && (
        <Popover
          open={isPopperOpen}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          slotProps={{ paper: { sx: { p: 1 } } }}
          onClose={handleClosePopper}
        >
          <Box display="flex" flexDirection="column" gap={1}>
            <Button
              onClick={() => handleSelectPrice(price?.mainPrice)}
              variant="outlined"
              className="big"
            >
              {formatReward(price?.mainPrice)}
            </Button>
            <Button
              onClick={() => handleSelectPrice(price?.altPrice1)}
              variant="outlined"
              className="big"
            >
              {formatReward(price?.altPrice1)}
            </Button>
          </Box>
        </Popover>
      )}
    </Root>
  );
};

export default PriceSection;
