import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Root } from 'containers/Checkout/BottomNavigation/index.styled';
import { ActionButton, RoundedButton } from 'layouts/BountyContentLayout/BottomNavigation/index.styled';

import { useAppDispatch } from 'hooks/useRedux';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';
import { setShowDownloadOverlay } from 'store/system/systemSlice';

import AccountCircle from 'assets/icons/AccountCircle';
import ArrowBack from 'assets/icons/ArrowBack';
import Download from 'assets/icons/Download';
import Wallet from 'assets/icons/Wallet';
import { WALLET_ROUTE } from 'constants/clientRoutes';

interface BottomNavigationProps {
  onPlaceOrder: () => void;
  isLoading?: boolean;
  canPlaceOrder?: boolean;
  hasPlaceOrder?: boolean;
  isLoggedIn?: boolean;
}

const BottomNavigation: FC<BottomNavigationProps> = ({
  onPlaceOrder,
  isLoading = false,
  canPlaceOrder = false,
  hasPlaceOrder = false,
  isLoggedIn = false,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handlePrev = () => navigate(-1);

  const handleSeeWallet = () => navigate(WALLET_ROUTE);

  const handleShowDownloadOverlay = () => dispatch(setShowDownloadOverlay(true));
  
  const handleSignIn = () => dispatch(openModalSignIn());

  return (
    <Root justifyContent="space-between">
      <ActionButton onClick={handlePrev}>
        <ArrowBack color="white" size={18} />
      </ActionButton>
      <Box display="flex" alignItems="center" gap={1}>
        <ActionButton onClick={handleSeeWallet}>
          <Wallet color="white" size={18} />
        </ActionButton>
        {isLoggedIn ? (
          <ActionButton onClick={handleShowDownloadOverlay}>
            <Download color="white" size={18} />
          </ActionButton>
        ) : (
          <ActionButton onClick={handleSignIn}>
            <AccountCircle color="white" size={18} />
          </ActionButton>
        )}
        {hasPlaceOrder && (
          <RoundedButton
            variant="contained"
            color="primary"
            onClick={onPlaceOrder}
            loading={isLoading}
            disabled={!canPlaceOrder}
          >
            <Typography variant="h2">
              {intl.formatMessage({ id: 'button.placeOrder' })}
            </Typography>
          </RoundedButton>
        )}
      </Box>
    </Root>
  );
};

export default BottomNavigation;
