import Add from 'assets/icons/Add';
import Apple from 'assets/icons/Apple';
import ArrowBack from 'assets/icons/ArrowBack';
import ArrowForward from 'assets/icons/ArrowForward';
import ArrowOutward from 'assets/icons/ArrowOutward';
import ArrowPause from 'assets/icons/ArrowPause';
import ArrowPlay from 'assets/icons/ArrowPlay';
import AttachFile from 'assets/icons/AttachFile';
import BarChart from 'assets/icons/BarChart';
import BlackCircle from 'assets/icons/BlackCircle';
import CancelCircle from 'assets/icons/CancelCircle';
import CancelCircleFilled from 'assets/icons/CancelCircleFilled';
import Category from 'assets/icons/Category';
import Chat from 'assets/icons/Chat';
import CheckCircle from 'assets/icons/CheckCircle';
import CloseFullscreenIcon from 'assets/icons/CloseFullscreenIcon';
import Code from 'assets/icons/Code';
import Copy from 'assets/icons/Copy';
import DoubleArrowRight from 'assets/icons/DoubleArrowRight';
import Download from 'assets/icons/Download';
import Favourite from 'assets/icons/Favourite';
import FavouriteOutline from 'assets/icons/FavouriteOutline';
import FilledCheckCircle from 'assets/icons/FilledCheckCircle';
import FilledHelp from 'assets/icons/FilledHelp';
import GeneratingTokens from 'assets/icons/GeneratingTokens';
import Google from 'assets/icons/Google';
import HourglassTop from 'assets/icons/HourglassTop';
import InfoIcon from 'assets/icons/InfoIcon';
import Launch from 'assets/icons/Launch';
import LinkIcon from 'assets/icons/LinkIcon';
import Lock from 'assets/icons/Lock';
import LockOpen from 'assets/icons/LockOpen';
import Mail from 'assets/icons/Mail';
import MoreHoriz from 'assets/icons/MoreHoriz';
import NotificationAdd from 'assets/icons/NotificationAdd';
import OpenInFullIcon from 'assets/icons/OpenInFullIcon';
import Panorama from 'assets/icons/Panorama';
import PersonOutline from 'assets/icons/PersonOutline';
import Remove from 'assets/icons/Remove';
import Report from 'assets/icons/Report';
import Send from 'assets/icons/Send';
import ShoppingCart from 'assets/icons/ShoppingCart';
import SouthEast from 'assets/icons/SouthEast';
import Storefront from 'assets/icons/Storefront';
import SubdirectoryArrowRight from 'assets/icons/SubdirectoryArrowRight';
import Sync from 'assets/icons/Sync';
import ThumbUp from 'assets/icons/ThumbUp';
import Timer from 'assets/icons/Timer';
import UnfoldMore from 'assets/icons/UnfoldMore';
import Verified from 'assets/icons/Verified';
import VerifiedOutlined from 'assets/icons/VerifiedOutlined';
import Visibility from 'assets/icons/Visibility';
import VisibilityOff from 'assets/icons/VisibilityOff';
import VolumeOff from 'assets/icons/VolumeOff';
import VolumeUp from 'assets/icons/VolumeUp';
import Wallet from 'assets/icons/Wallet';

export const icons = [
  { Icon: ArrowBack, name: 'ArrowBack' },
  { Icon: Add, name: 'Add' },
  { Icon: ArrowForward, name: 'ArrowForward' },
  { Icon: ArrowOutward, name: 'ArrowOutward' },
  { Icon: ArrowPlay, name: 'ArrowPlay' },
  { Icon: ArrowPause, name: 'ArrowPause' },
  { Icon: BlackCircle, name: 'BlackCircle' },
  { Icon: CancelCircle, name: 'CancelCircle' },
  { Icon: CancelCircleFilled, name: 'CancelCircleFilled' },
  { Icon: Category, name: 'Category' },
  { Icon: CheckCircle, name: 'CheckCircle' },
  { Icon: CloseFullscreenIcon, name: 'CloseFullscreenIcon' },
  { Icon: FilledCheckCircle, name: 'FilledCheckCircle' },
  { Icon: FilledHelp, name: 'FilledHelp' },
  { Icon: GeneratingTokens, name: 'GeneratingTokens' },
  { Icon: HourglassTop, name: 'HourglassTop' },
  { Icon: InfoIcon, name: 'InfoIcon' },
  { Icon: MoreHoriz, name: 'MoreHoriz' },
  { Icon: SouthEast, name: 'SouthEast' },
  { Icon: SubdirectoryArrowRight, name: 'SubdirectoryArrowRight' },
  { Icon: Sync, name: 'Sync' },
  { Icon: Timer, name: 'Timer' },
  { Icon: Verified, name: 'Verified' },
  { Icon: Storefront, name: 'Storefront' },
  { Icon: ThumbUp, name: 'ThumbUp' },
  { Icon: FavouriteOutline, name: 'FavouriteOutline' },
  { Icon: Favourite, name: 'Favourite' },
  { Icon: Chat, name: 'Chat' },
  { Icon: NotificationAdd, name: 'NotificationAdd' },
  { Icon: OpenInFullIcon, name: 'OpenInFullIcon' },
  { Icon: Panorama, name: 'Panorama' },
  { Icon: Remove, name: 'Remove' },
  { Icon: DoubleArrowRight, name: 'DoubleArrowRight' },
  { Icon: Send, name: 'Send' },
  { Icon: AttachFile, name: 'AttachFile' },
  { Icon: VerifiedOutlined, name: 'VerifiedOutlined' },
  { Icon: Download, name: 'Download' },
  { Icon: LinkIcon, name: 'LinkIcon' },
  { Icon: Lock, name: 'Lock' },
  { Icon: LockOpen, name: 'LockOpen' },
  { Icon: Report, name: 'Report' },
  { Icon: Code, name: 'Code' },
  { Icon: Mail, name: 'Mail' },
  { Icon: Google, name: 'Google' },
  { Icon: Apple, name: 'Apple' },
  { Icon: Visibility, name: 'Visibility' },
  { Icon: VisibilityOff, name: 'VisibilityOff' },
  { Icon: PersonOutline, name: 'PersonOutline' },
  { Icon: Launch, name: 'Launch' },
  { Icon: Copy, name: 'Copy' },
  { Icon: UnfoldMore, name: 'UnfoldMore' },
  { Icon: BarChart, name: 'BarChart' },
  { Icon: VolumeUp, name: 'VolumeUp' },
  { Icon: VolumeOff, name: 'VolumeOff' },
  { Icon: Wallet, name: 'Wallet' },
  { Icon: ShoppingCart, name: 'ShoppingCart' },
];
