import { useEffect, useRef } from 'react';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';
import { TConductorInstance } from 'react-canvas-confetti/src/types';

import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { isFiredSelector } from 'store/system/selectors';
import { fireConfetti } from 'store/system/systemSlice';

const Confetti = () => {
  const shouldFireConfetti = useAppSelector(isFiredSelector);
  const dispatch = useAppDispatch();
  const controller = useRef<TConductorInstance>(null);

  useEffect(() => {
    if (shouldFireConfetti) {
      onShoot();
      dispatch(fireConfetti(false));
    }
  }, [shouldFireConfetti]);

  const initHandler = ({ conductor }: { conductor: TConductorInstance }) => {
    controller.current = conductor;
  };

  const onShoot = () => {
    controller.current?.shoot();
  };

  return (
    <Realistic
      onInit={initHandler}
    />
  );
};

export default Confetti;
