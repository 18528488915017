import { child, push, set } from 'firebase/database';

import { runPostApiRequest } from 'services/Api';
import { getUserDataRef } from 'services/FirebaseRefs';

import { EVENTS_ROUTE } from 'constants/apiRoutes';
import { QueueActions } from 'constants/queueActions';
import { CardInfo, CreateCreditCart } from 'models/cardInfo.interface';
import { formatRoute } from 'utils/formatters';

export const saveCreditCard = async ({ userId, token, cardInfo }: CreateCreditCart): Promise<CardInfo> => {
  const ref = push(child(getUserDataRef(userId), 'payments'));
  const card = {
    ...cardInfo,
    id: ref.key as string,
  } as CardInfo;

  await set(ref, card);

  await runPostApiRequest(
    formatRoute(EVENTS_ROUTE, { action: QueueActions.NewCreditCard }),
    {
      cardId: card.id,
      stripeTokenId: token.id,
      cardInfo: card,
    },
  );

  return card;
};
