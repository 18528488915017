import bigInt, { BigInteger } from 'big-integer';

import {
  CtrlTypes, KeyCategory, SortBy, StreamCodes,
} from 'constants/enums';
import { BountyResponse } from 'models/bountyResponse.interface';
import { Comment } from 'models/comment.interface';
import { ProductInfo } from 'models/product.interface';
import { ResponseKeyInfo } from 'models/responseKeyInfo';
import { isDraftState } from 'utils/response';

const MAX_VALUE_LONG = '9223372036854775807';
const HEX_ARRAY = '0123456789ABCDEF'.split('');
const KEY_SEP = '-';

export const stitchParts = (tuple: { [key: string]: string }) => {
  if (!Object.keys(tuple)) {
    return '';
  }

  return Object.values(tuple).join(KEY_SEP);
};

const longToBytes = (nr: BigInteger) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const byteArray: BigInteger[] = [0, 0, 0, 0, 0, 0, 0, 0];
  let cloneNr = nr;

  byteArray.forEach((_, index) => {
    const localIdx = 7 - index;
    byteArray[localIdx] = cloneNr.and(0xff);
    cloneNr = cloneNr.shiftRight(8);
  });

  return byteArray;
};

const bytesToHex = (bytes: BigInteger[]) => {
  let hexa = '';

  bytes.forEach((item: BigInteger) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-bitwise
    const v = item & 0xFF;
    // eslint-disable-next-line no-bitwise
    hexa = `${hexa}${HEX_ARRAY[v >>> 4]}${HEX_ARRAY[v & 0x0F]}`;
  });

  return hexa;
};

export const getTimestampAsSortKeyAsc = (ts: number) => {
  const longValue:BigInteger = bigInt(ts);
  return bytesToHex(longToBytes(longValue));
};

export const getTimestampAsSortKeyDesc = (ts: number) => {
  const longValue:BigInteger = bigInt(MAX_VALUE_LONG).subtract(ts);
  return bytesToHex(longToBytes(longValue));
};

export const getOutboundPriority = (isDraft: boolean, createdAt: number) => {
  const category = isDraft ? KeyCategory.Important : KeyCategory.Normal;
  const tuple = {
    type: KeyCategory.Normal,
    list: StreamCodes.Regular,
    category,
    sortl: getTimestampAsSortKeyDesc(createdAt),
    ctrl: CtrlTypes.Shown,
  };

  return stitchParts(tuple);
};

export const getPriorityForProduct = (productInfo: Partial<ProductInfo>) => (
  getTimestampAsSortKeyAsc(productInfo.addedAt ? productInfo.addedAt : 0)
);

const latest = (updatedAt: number, createdAt: number) => {
  const ts = Math.max(updatedAt, createdAt);
  return ts > 0 ? ts : new Date().getTime();
};

export const forResponse = (response: Partial<BountyResponse>): ResponseKeyInfo => {
  const { stats } = response;
  const nrComments = (stats && stats.commentCount) || 0;
  const nrLikes = (stats && stats.likesCount) || 0;
  const starRating = (response.rating && response.rating.totalStars) || 0;

  const creatorId = response.creator != null ? response.creator.id : null;
  const bountyId = response.bountyInfo != null ? response.bountyInfo.id : null;

  return {
    creatorId,
    bountyId,
    state: response.state || null,
    pinnedAt: response.pinnedAt || null,
    starRating,
    nrComments,
    nrLikes,
    createdAt: response.updatedAt || null,
    updatedAt: response.updatedAt || null,
  };
};

export const getOutboundPriorityForResponse = (response: BountyResponse) => {
  const responseKeyInfo = forResponse(response);
  const isDraft = responseKeyInfo.state ? isDraftState(responseKeyInfo.state) : false;
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);

  return getOutboundPriority(isDraft, ts);
};

export const getSortKey = (responseKeyInfo: ResponseKeyInfo, type: string, nr: number) => {
  let ts = nr;
  const tuple: Record<string, any> = {
    category: KeyCategory.Normal,
  };

  if (responseKeyInfo.pinnedAt) {
    tuple.category = KeyCategory.Important;
    ts = responseKeyInfo.pinnedAt;
  }

  tuple.type = type;
  tuple.list = StreamCodes.Regular;
  tuple.sort1 = getTimestampAsSortKeyDesc(ts);
  tuple.ctrl = CtrlTypes.Shown;

  return stitchParts(tuple);
};

const getSortKeyByRecommendationApplicant = (responseKeyInfo: ResponseKeyInfo) => {
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId!, ts);
};

const getSortKeyByCreator = (responseKeyInfo: ResponseKeyInfo) => {
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);
  return getSortKey(responseKeyInfo, responseKeyInfo.creatorId!, ts);
};

const getSortKeyByBounty = (responseKeyInfo: ResponseKeyInfo) => {
  const ts = latest(responseKeyInfo.updatedAt!, responseKeyInfo.createdAt!);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId!, ts);
};

const getSortKeyByBountyAndPopularity = (responseKeyInfo: ResponseKeyInfo) => {
  const nr = (responseKeyInfo.nrLikes || 0) + (responseKeyInfo.starRating || 0);
  return getSortKey(responseKeyInfo, responseKeyInfo.bountyId!, nr);
};

const getSortKeyByBountyMostDiscussed = (responseKeyInfo: ResponseKeyInfo) => (
  getSortKey(responseKeyInfo, responseKeyInfo.bountyId!, responseKeyInfo.nrComments!)
);

export const updateAllSortKeys = (response: Partial<BountyResponse>) => {
  const responseKeyInfo = forResponse(response);

  response.creatorUpdatedAtSortKey = getSortKeyByCreator(responseKeyInfo);
  response.bountyCreatedAtSortKey = getSortKeyByBounty(responseKeyInfo);
  response.bountyPopularitySortKey = getSortKeyByBountyAndPopularity(responseKeyInfo);
  response.bountyDiscussedSortKey = getSortKeyByBountyMostDiscussed(responseKeyInfo);

  if (response.recommendation?.applicant) {
    response.recommendation.applicant = getSortKeyByRecommendationApplicant(responseKeyInfo);
  }

  return response;
};

export const getPriorityForComment = (comment: Partial<Comment>) => {
  const {
    text, reward, editedAt, sentAt,
  } = comment;
  let category = KeyCategory.Important;

  if (!text || (text && !text.trim())) {
    category = KeyCategory.Unimporant;
  } else if (!reward) {
    category = KeyCategory.Normal;
  }

  const ts = editedAt || sentAt;
  return ['00', category, 'GG', getTimestampAsSortKeyAsc(ts as number)].join(KEY_SEP);
};

export const getSortKeyForResponse = (key: Partial<SortBy>, responseKeyInfo: ResponseKeyInfo) => {
  switch (key) {
    case SortBy.CreatorUpdatedAt:
      return getSortKeyByCreator(responseKeyInfo);
    case SortBy.BountyCreated:
      return getSortKeyByBounty(responseKeyInfo!);
    case SortBy.BountyPopularity:
      return getSortKeyByBountyAndPopularity(responseKeyInfo);
    case SortBy.BountyDiscussed:
      return getSortKeyByBountyMostDiscussed(responseKeyInfo);
    default:
        console.log('illegal key'); // eslint-disable-line
      return null;
  }
};
