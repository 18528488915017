import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,

  '& .more': {
    color: theme.palette.text.primary,
  },
}));

export const RatingWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center' ,
  justifyContent: 'space-between',
  gap: theme.spacing(1) ,
  marginTop: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));
