import { Box, Tab, Tabs, Typography } from '@mui/material';
import { onValue, orderByChild, query } from 'firebase/database';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Carousel from 'components/Carousel';
import DownloadAppOverlay from 'components/DownloadAppOverlay';
import LoadingStory from 'components/LoadingStory';
import BottomNavigation from 'containers/ProductDetails/BottomNavigation';
import DescriptionTab from 'containers/ProductDetails/DescriptionTab';
import PriceSection from 'containers/ProductDetails/PriceSection';
import SigninInvitation from 'containers/SigninInvitation';

import { getBountyDetails } from 'services/Bounty';
import { getUserBountyCommentsRef } from 'services/FirebaseRefs';

import Panorama from 'assets/icons/Panorama';
import { Bounty } from 'models/bounty.interface';
import { Comment } from 'models/comment.interface';
import { handleApiErrors } from 'utils/error';
import { sortByTimestamp } from 'utils/utils';

import { Content, EmptyBackground } from './index.styled';

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-${index}`,
  };
}

const ProductDetails = () => {
  const intl = useIntl();
  const [bounty, setBounty] = useState<Bounty>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { bountyId = '' } = useParams();
  const [tab, setTab] = useState(0);
  const [comments, setComments] = useState<Comment[]>([]);
  const attachments = bounty?.attachments?.attachments
    ? Object.values(bounty.attachments.attachments)
    : [];
  const hasTabs = !!bounty?.product?.additionalInfo;

  useEffect(() => {
    if (bountyId) {
      fetchBountyDetails();
      fetchProductReviews();
    }
  }, [bountyId]);

  const fetchBountyDetails = async () => {
    setIsLoading(true);

    try {
      const response = await getBountyDetails(bountyId);
      setBounty(response?.data);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductReviews = async () => {
    setIsLoading(true);
    const refQuery = query(getUserBountyCommentsRef(bountyId), orderByChild('sentAt'));

    onValue(
      refQuery,
      (dataSnapshot) => {
        const data = dataSnapshot.val();
        setComments(data ? sortByTimestamp<Comment>(Object.values(data), 'sentAt') : []);
        setIsLoading(false);
      },
      () => {
        toast.error(intl.formatMessage({ id: 'error.cannotRetrieveComments' }));
        setIsLoading(false);
      },
    );
  };

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  if (isLoading) {
    return <LoadingStory />;
  }

  return (
    <Box height="100%" position="relative" >
      <Content className="hidden-scroll">
        {attachments?.length > 0 ? (
          <Carousel
            attachments={attachments}
            offset={0}
            classNameStepper="stepper"
          />
        ) : (
          <EmptyBackground>
            <Panorama size={80} />
          </EmptyBackground>
        )}
        {hasTabs && (
          <Tabs value={tab} onChange={handleTabChange} aria-label="tab" sx={{ py: 3, ml: 2.5 }}>
            <Tab
              label={intl.formatMessage({ id: 'button.description' })}
              {...a11yProps(1)}
            />
            <Tab
              label={intl.formatMessage({ id: 'button.additionalInfo' })}
              {...a11yProps(0)}
            />
          </Tabs>
        )}
        <Box px={2.5} pb={3} pt={hasTabs ? 0 : 3}>
          {tab === 0
            ? <DescriptionTab bounty={bounty} comments={comments} />
            : (
              <Typography color="textPrimary" variant="h2" fontWeight={400}>
                {bounty?.product?.additionalInfo}
              </Typography>
            )}
        </Box>
      </Content>
      <PriceSection bounty={bounty} />
      <BottomNavigation />
      <SigninInvitation />
      <DownloadAppOverlay />
    </Box>
  );
};

export default ProductDetails;
