import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { mainContentOffset, mainContentOffsetMobile } from 'assets/theme/customSpacing';

export const Content = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.general.darkGrey4,
  height: `calc(100% - ${mainContentOffset}px)`,
  borderRadius: '10px',
  padding: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),

  [theme.breakpoints.down('md')]: {
    height: `calc(100% - ${mainContentOffsetMobile}px)`,
  },
}));
