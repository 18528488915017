import { Typography, Grid2 as Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import ProductCard from 'containers/MerchantProfile/ProductCard';
import ProductCardSkeleton from 'containers/MerchantProfile/ProductCard/index.skeleton';

import { getProducts } from 'services/Products';

import { PRODUCT_DETAILS_ROUTE } from 'constants/clientRoutes';
import { paginationItemsPerPage } from 'constants/general';
import { Bounty } from 'models/bounty.interface';
import { handleApiErrors } from 'utils/error';
import { formatRoute } from 'utils/formatters';

const Products = () => {
  const intl = useIntl();
  const { userId } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState<Bounty[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);

  const hasMore = useMemo(() => totalCount > products?.length, [totalCount, products]);

  useEffect(() => {
    if (userId) {
      fetchProducts(userId);
    }
  }, [userId]);

  const fetchProducts = async (id: string, page = 0) => {
    try {
      setIsLoading(true);
      const { data } = await getProducts(id, page);
      setProducts((prevProducts) => ([
        ...(page ? prevProducts : []),
        ...data?.list ?? []
      ]));
      setTotalCount(data?.totalCount);
      setCurrentPage(page);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  const loadProducts = async () => {
    const maxPage = totalCount ? Math.floor(totalCount / paginationItemsPerPage) : 0;
    const newPage = currentPage + 1;

    if (isLoading || !userId) {
      return;
    }

    if (newPage > maxPage) {
      setIsLoading(false);
    } else {
      fetchProducts(userId, newPage);
    }
  };

  const handleClickProduct = (bountyId: string) => {
    navigate(formatRoute(PRODUCT_DETAILS_ROUTE, { bountyId }));
  };

  return (
    <div>
      {products?.length > 0 || isLoading ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={loadProducts}
          hasMore={hasMore}
          useWindow={false}
          threshold={200}
          getScrollParent={() => document.getElementById('profileContent')}
        >
          <Grid container spacing={2}>
            {products?.map((product) => (
              <Grid
                size={6}
                key={product.id}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleClickProduct(product?.id)}
              >
                <ProductCard bounty={product} />
              </Grid>
            ))}
            {isLoading && (
              <>
                <Grid size={6}>
                  <ProductCardSkeleton/>
                </Grid>
                <Grid size={6}>
                  <ProductCardSkeleton/>
                </Grid>
                <Grid size={6}>
                  <ProductCardSkeleton/>
                </Grid>
                <Grid size={6}>
                  <ProductCardSkeleton/>
                </Grid>
              </>
            )}
          </Grid>
        </InfiniteScroll>
      ) : (
        <Typography variant="h2" textAlign="center">
          {intl.formatMessage({ id: 'label.noProducts' })}
        </Typography>
      )}
    </div>
  );
};

export default Products;
