import { Box, List, ListItem, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Button } from 'containers/TimerHeadsUp/index.styled';

import { Reward } from 'models/money.interface';

interface InsufficientAmountProps {
  onClose: () => void;
  insufficientAmount: Reward | null;
}

const InsufficientAmount: FC<InsufficientAmountProps> = ({
  onClose,
  insufficientAmount,
}) => {
  const intl = useIntl();

  return (
    <Box width="100%">
      <Typography variant="h1" mb={3}>
        {intl.formatMessage({ id: 'label.completeOrder' })}
      </Typography>
      <Typography variant="body1">
        {intl.formatMessage({ id: 'label.toCompleteOrderNeed' })}:
      </Typography>
      {insufficientAmount?.points && (
        <List>
          {Object.values(insufficientAmount?.points)?.map(({ amount, currency }) => (
            <ListItem key={currency} sx={{ px: 0 }}>
              <Typography variant="body1" fontWeight={600}>
                - {`${amount} ${currency}`}
              </Typography>
            </ListItem>
          ))}
        </List>
      )}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        className="big"
        onClick={onClose}
        sx={{ mt: 1 }}
      >
        {intl.formatMessage({ id: 'button.close' })}
      </Button>
    </Box>
  );
};

export default InsufficientAmount;
