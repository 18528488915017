import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { InvalidReason } from 'constants/enums';
import { InvalidProduct } from 'models/product.interface';

const renderBoldChunks = (chunks: ReactNode[]) => <b key="bold">{chunks}</b>;

export const getProductErrorMessage = (
  invalidProduct: InvalidProduct,
  merchantName: string,
  deliveryCountry?: string,
): ReactNode | null => {
  let isDeliverySupported = true;
  let hasSufficientAmount = true;
  let errorMessage:ReactNode | null = null;

  invalidProduct?.reasons?.some((reason) => {
    if (reason === InvalidReason.NoDeliveryToCountry) {
      isDeliverySupported = false;
    }

    if (reason === InvalidReason.InsufficientProductAmount) {
      hasSufficientAmount = false;
    }
  });

  if (!isDeliverySupported && deliveryCountry) {
    errorMessage = (
      <FormattedMessage
        id="label.cannotDeliverItem"
        values={{ country: deliveryCountry, merchantName, b: renderBoldChunks }}
      />
    );
  }

  if (!hasSufficientAmount) {
    errorMessage = <FormattedMessage id="label.itemOutOfStock" />;
  }

  return errorMessage;
};
