
const MuiTab = {
  styleOverrides: {
    root: ({ theme }: any) => ({
      marginRight: theme.spacing(2),
      minHeight: '22px',
      margin: theme.spacing(0, 2.5, 0, 0),
      height: '42px',
      color: theme.palette.common.black,
      fontSize: '18px',
      fontWeight: 700,
      padding: theme.spacing(2, 4),
      borderRadius: '25px',
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.common.white,

      '&.Mui-selected': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      },

      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 1, 0, 0),
        fontSize: '16px',
        padding: theme.spacing(1, 1.5),
      }
    }),
  },
};

export default MuiTab;
