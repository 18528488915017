import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.error.main,
  padding: theme.spacing(1, 1.5),
  whiteSpace: 'nowrap',
  textAlign: 'center',
}));
