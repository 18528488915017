import flavourConfig from 'configuration/env';
import { isTestChannel } from 'utils/env';

export const getCampaignsDashboard = (email?: string|null) => {
  const encodedEmail = email ? encodeURIComponent(email) : '';

  if (isTestChannel()) {
    return encodedEmail
      ? `${flavourConfig.campaignTestingUrl}/visit?loginAs=${encodedEmail}&redirectTo=/`
      : `${flavourConfig.campaignTestingUrl}/visit?redirectTo=/`;
  }

  return encodedEmail
    ? `${flavourConfig.campaignUrl}/visit?loginAs=${encodedEmail}&redirectTo=/`
    : `${flavourConfig.campaignUrl}/visit?redirectTo=/`;
};

export const getAndroidIntentUrl = () => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const urlWithoutProtocol = `${url.host}${url.pathname}?visit=true`;
  return `intent://${urlWithoutProtocol}#Intent;scheme=${url.protocol.replace(':', '')};package=com.android.chrome;component=com.android.chrome/com.google.android.apps.chrome.Main;end;target=browser`;
};
